import Home from '../pages/Home';
import Login from '../pages/signs/Login';
import StatisticsChart from '../pages/StatisticsChart'

// marketing
import Supervisor from '../pages/marketing/Supervisors';
// pages in marketing

// sales
import Sales from '../pages/sales/Sales';
import Statistics from '../pages/sales/Statistics';
// pages in sales
// settings
import Users from '../pages/settings/Users';

// staff
import SupervisorsInfo from '../pages/marketing/SupervisorsInfo'

// import NewReport from '../pages/marketing/NewReport'

import DoubleVisitReport from '../pages/reports/DoubleVisitReport'
import DoubleVisitsReports from '../pages/reports/DoubleVisitsReports'
import Reports from '../pages/reports/Reports'

import UsersTree from '../pages/settings/UsersTree'

// medical rep zone
import Visits from '../pages/medicalRepZone/visits/Visits';
import VisitsGroupByMedicalRep from '../pages/medicalRepZone/visits/VisitsGroupByMedicalRep'
import MedicalRepPlan from '../pages/medicalRepZone/MedicalRepPlan'
import DayOffRequests from '../pages/medicalRepZone/DayOffRequests';
import VisitFrequencyReport from '../pages/medicalRepZone/VisitFrequencyReport'
import Exams from '../pages/medicalRepZone/Exams'
import ViewExamResults from '../pages/medicalRepZone/ViewExamResults'
import Salaries from '../pages/medicalRepZone/Salaries';
import VisitsTab from '../pages/medicalRepZone/visits/VisitsTab';

// inventory
import JournalDeliveryOfSamples from '../pages/inventory/JournalDeliveryOfSamples'
import JournalItems from '../pages/inventory/JournalItems'
import ProductsStatement from '../pages/inventory/ProductsStatement'
import SamplesStatement from '../pages/inventory/SamplesStatement'
import SupervisorRemainingSamples from '../pages/inventory/SupervisorRemainingSamples'
import Samples from '../pages/inventory/Samples'
import PharmaceuticalForms from '../pages/inventory/PharmaceuticalForms'
import MinimalInterface from '../pages/inventory/MinimalInterface'
import WarehousesStatistics from '../pages/inventory/WarehousesStatistics'
import WarehouseTabs from '../pages/inventory/WarehouseTabs'

// management
import Products from '../pages/management/Products';
import Regions from '../pages/management/Regions';

// staff
import Staff from '../pages/management/staff/Staff';
import Teams from '../pages/management/staff/Teams'
import MedicalRepInfo from '../pages/management/staff/MedicalRepInfo'
import medicalRepSamples from '../pages/management/staff/medicalRepSamples'
import MedicalRepRates from '../pages/management/staff/MedicalRepRates'
import AdministrativePlan from '../pages/management/staff/AdministrativePlan'
import ManagingRolesAndPermissions from '../pages/management/staff/ManagingRolesAndPermissions';
import EmployeeData from '../pages/management/staff/EmployeeData'
import DailyReport from '../pages/management/staff/DailyReport'

// customers
import Customers from '../pages/management/customers/Customers'
import SpecializationsManagement from '../pages/management/customers/SpecializationsManagement'
import ClassificationManagement from '../pages/management/customers/ClassificationManagement'
import ModificationRequests from '../pages/management/customers/ModificationRequests'
import AccountsTree from '../pages/management/customers/AccountsTree'
import VisitedDoctors from '../pages/management/customers/VisitedDoctors'

// administrative zone
import AdministrativeVisit from '../pages/administrativeZone/AdministrativeVisit'
import AdministrativeReport from '../pages/administrativeZone/AdministrativeReport'
// old form for ramaPharma
import SupervisorsReport from '../pages/administrativeZone/SupervisorsReport'
import SupervisorsReports from '../pages/administrativeZone/SupervisorsReports';
import WeeklyReport from '../pages/administrativeZone/WeeklyReport'

export default [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            i18nKey: 'home'
        }
    },
    {
        name: 'login',
        path: '/login',
        component: Login,
    },
    {
        name: 'statistics-chart',
        path: '/statistics-chart/:type',
        component: StatisticsChart,
        meta: {
            i18nKey: 'statistics-chart'
        }
    },

    // marketing
    {
        name: 'day-off-requests',
        path: '/day-off-requests',
        component: DayOffRequests,
        meta: {
            i18nKey: 'day-off-requests'
        }
    },
    {
        name: 'staff',
        path: '/staff',
        component: Staff,
        meta: {
            i18nKey: 'staff'
        }
    },
    // {
    //     name: 'administrative-reports',
    //     path: '/administrative-reports',
    //     component: SupervisorsReports,
    //     // meta: {
    //     //     i18nKey: 'supervisor-reports'
    //     // },
    //     permissionKey: 'WeeklyReports'
    // },
    {
        name: 'reports',
        path: '/reports',
        component: Reports,
        meta: {
            i18nKey: 'reports'
        },
        children: [
            {
                name: 'administrative-reports',
                path: '/reports/administrative-reports',
                component: SupervisorsReports,
                meta: {
                    i18nKey: 'administrative-reports'
                },
                permissionKey: 'WeeklyReports'
            },
            {
                name: 'double-visits',
                path: '/reports/double-visits-reports',
                component: DoubleVisitsReports,
                meta: {
                    i18nKey: 'double-visits'
                }
            }
        ]
    },
    {
        name: 'supervisors',
        path: '/supervisors',
        component: Supervisor,
        meta: {
            i18nKey: 'supervisors'
        }
    },
    {
        name: 'samples',
        path: '/samples',
        component: Samples,
    },
    {
        name: 'customers',
        path: '/customers',
        component: Customers,
        meta: {
            i18nKey: 'customers'
        }
    },
    {
        name: 'Exams',
        path: '/exams',
        component: Exams,
        meta: {
            i18nKey: 'exams'
        },
        permissionKey: 'Exams'
    },
    {
        name: 'salaries',
        path: '/salaries',
        component: Salaries,
        meta: {
            i18nKey: 'salaries'
        },
        permissionKey: 'Salaries'
    },
    {
        name: 'regions-management',
        path: '/regions-management',
        component: Regions,
        meta: {
            i18nKey: 'regions-management'
        }
    },

    // pages in marketing
    {
        name: 'visited-doctors',
        path: '/visited-doctors',
        component: VisitedDoctors,
        meta: {
            i18nKey: 'visited-doctors'
        }
    },
    {
        name: 'medical-rep-samples',
        path: '/medical-rep-samples/:id',
        component: medicalRepSamples,
        meta: {
            i18nKey: 'medical-rep-samples'
        }
    },
    {
        name: 'supervisor-remaining-samples',
        path: '/supervisor-remaining-samples',
        component: SupervisorRemainingSamples,
        meta: {
            i18nKey: 'supervisor-remaining-samples'
        }
    },
    {
        name: 'modification-requests',
        path: '/modification-requests',
        component: ModificationRequests,
        meta: {
            i18nKey: 'modification-requests'
        },
        permissionKey: 'EntityModificationRequests'
    },
    {
        name: 'specializations-management',
        path: '/specializations-management',
        component: SpecializationsManagement,
        meta: {
            i18nKey: 'specializations-management'
        }
    },
    {
        name: 'medical-rep-rates',
        path: '/medical-rep-rates/:medicalRepName',
        component: MedicalRepRates,
        meta: {
            i18nKey: 'medical-rep-rates'
        }
    },
    {
        name: 'exam-results',
        path: '/exam-results/:id',
        component: ViewExamResults,
        meta: {
            i18nKey: 'exam-results'
        },
        permissionKey: 'exams'
    },
    {
        name: 'medical-rep-info',
        path: '/medical-rep-info',
        component: MedicalRepInfo,
        children: [
            {
                name: 'medical-rep-info-day-requests',
                path: '/day-requests/:id',
                component: DayOffRequests,
                meta: {
                    i18nKey: 'medical-rep-info-day-requests'
                },
                permissionKey: 'Leaves'
            },
            {
                name: 'medical-samples',
                path: '/medical-samples/:id',
                component: medicalRepSamples,
                meta: {
                    i18nKey: 'medical-samples'
                }
            },
            {
                name: 'medical-rates',
                path: '/medical-rates/:id',
                component: MedicalRepRates,
                meta: {
                    i18nKey: 'medical-rates'
                }
            },
            {
                name: 'medical-rep-personal-data',
                path: '/medical-rep-personal-data/:id?',
                component: EmployeeData,
                meta: {
                    i18nKey: 'medical-rep-personal-data'
                }
            }
        ]
    },

    {
        name: 'supervisors-info',
        path: '/supervisors-info',
        component: SupervisorsInfo,
        children: [
            {
                name: 'supervisors-samples',
                path: '/supervisors-samples/:id',
                component: Samples,
                meta: {
                    i18nKey: 'supervisors-samples'
                }
            },
            {
                name: 'supervisors-personal-data',
                path: '/supervisors-personal-data/:id',
                component: EmployeeData,
                meta: {
                    i18nKey: 'supervisors-personal-data'
                }
            }
        ]
    },
    {
        name: 'journal-items',
        path: '/journal-items',
        component: JournalItems,
        meta: {
            i18nKey: 'journal-items'
        }
    },
    {
        name: 'journal-delivery-of-samples',
        path: '/journal-delivery-of-samples',
        component: JournalDeliveryOfSamples,
        meta: {
            i18nKey: 'journal-delivery-of-samples'
        }
    },

    {
        name: 'products-statement',
        path: '/products-statement',
        component: ProductsStatement,
        meta: {
            i18nKey: 'products-statement'
        }
    },
    {
        name: 'supervisors-report',
        path: '/supervisors-report/:id?',
        component: SupervisorsReport,
        meta: {
            i18nKey:'supervisors-report'
        }
    },
    {
        name: 'weekly-report',
        path: '/weekly-report/:id?',
        component: WeeklyReport,
        meta: {
            i18nKey:'weekly-report'
        }
    },
    // sales
    {
        name: 'products',
        path: '/products',
        component: Products,
        meta: {
            i18nKey: 'products'
        }
    },
    {
        name: 'sales',
        path: '/sales',
        component: Sales,
    },
    {
        name: 'statistics',
        path: '/statistics',
        component: Statistics,
    },
    // pages in sales
    {
        name: 'pharmaceutical-forms',
        path: '/pharmaceutical-forms',
        component: PharmaceuticalForms,
        meta: {
            i18nKey: 'pharmaceutical-forms'
        }
    },

    // sitting
    {
        name: 'Roles',
        path: '/Roles/:id?',
        component: ManagingRolesAndPermissions,
        meta: {
            i18nKey: 'roles'
        },
        permissionKey: 'Roles'
    },
    {
        name: 'users',
        path: '/users',
        component: Users,
        meta: {
            i18nKey: 'users'
        }
    },

    { 
        name: 'classification-management',
        path: '/classification-management',
        component: ClassificationManagement,
        meta: {
            i18nKey: 'classification-management'
        }
    },

    {
        name: 'visit-frequency-report',
        path: '/visit-frequency-report',

        component: VisitFrequencyReport,
        meta: {
            i18nKey: 'visit-frequency-report'
        }
    },
    {
        name: 'medical-rep-plan',
        path: '/medical-rep-plan/:id?',
        component: MedicalRepPlan,
        meta: {
            i18nKey: 'medical-rep-plan'
        },
        permissionKey: 'MedicalPlans'
    },
    {
        name: 'double-visit-report',
        path: '/double-visit-report/:id?',
        component: DoubleVisitReport,
        meta: {
            i18nKey: 'double-visit-report'
        }
    },
    {
        name: 'double-visits-reports',
        path: '/double-visits-reports',
        component: DoubleVisitsReports,
        meta: {
            i18nKey: 'double-visits-reports'
        }
    },
    {
        name: 'users-tree',
        path: '/users-tree/:id?',
        component: UsersTree,
        meta: {
            i18nKey: 'users-tree'
        }
    },
    {
        name: 'administrative-visit',
        path: '/administrative-visit',
        component: AdministrativeVisit,
        meta: {
            i18nKey: 'administrative-visit'
        }
    },
    {
        name: 'administrative-plan',
        path: '/administrative-plan/:id?',
        component: AdministrativePlan,
        meta: {
            i18nKey: 'administrative-plan'
        }
    },
    {
        name: 'accounts-tree',
        path: '/accounts-tree',
        component: AccountsTree,
        meta: {
            i18nKey: 'accounts-tree'
        }
    },
    {
        name: 'teams',
        path: '/teams',
        component: Teams,
        meta: {
            i18nKey: 'teams'
        },
        permissionKey: 'Roles'
    },
    {
        name: 'my-administrative-reports',
        path: '/administrative-reports',
        component: AdministrativeReport,
        meta: {
            i18nKey: 'administrative-reports'
        },
        children: [
            {
                name: 'administrative-double-visits',
                path: '/administrative-reports/double-visits-reports',
                component: DoubleVisitsReports,
                meta: {
                    i18nKey: 'double-visits'
                }
            },
            {
                name: 'administrative-weekly-report',
                path: 'administrative-reports/weekly-report/:id?',
                component: WeeklyReport,
                meta: {
                    i18nKey:'weekly-report'
                }
            },
            {
                name: 'administrative-visits',
                path: '/reports/administrative-visit',
                component: AdministrativeVisit,
                meta: {
                    i18nKey: 'administrative-reports'
                },
            }
        ]
    },
    {
        name: 'visits-tab',
        path: '/visits-tab',
        component: VisitsTab,
        meta: {
            i18nKey: 'visits-tab'
        },
        children: [
            {
                name: 'visits',
                path: '/visits',
                component: Visits,
                meta: {
                    i18nKey: 'visits'
                }
            },
            {
                name: 'visits-group-by-medical-rep',
                path: '/visits-group-by',
                component: VisitsGroupByMedicalRep,
                meta: {
                    i18nKey: 'visits'
                },
            }
        ]
    },
   
    {
        name: 'minimal-interface',
        path: '/minimal-interface',
        component: MinimalInterface,
        meta: {
            i18nKey: 'minimal-interface'
        },
    },
   
    {
        name: 'warehouse-tabs',
        path: '/warehouse-tabs',
        component: WarehouseTabs,
        meta: {
            i18nKey: 'warehouse-tabs'
        },
        children: [
            {
                name: 'samples-statement',
                path: '/samples-tabs/samples-statement',
                component: SamplesStatement,
                meta: {
                    i18nKey: 'samples-statement'
                }
            },
            {
                name: 'warehouses-statistics',
                path: '/warehouses-statistics',
                component: WarehousesStatistics,
                meta: {
                    i18nKey: 'warehouses-statistics'
                }
            },
        ]
    },
    {
        name: 'daily-report',
        path: '/daily-report',
        component: DailyReport,
        meta: {
            i18nKey: 'daily-report'
        }
    },
    {
        path: '*',
        redirect: '/'
    }
]