export default {
    home: 'الصفحة الرئيسية',
    'medRep-zone ': 'مساحة المندوب',
    visits: 'الزيارات',
    'med-rep-plan': 'خطة مندوب',
    'day-off-requests': 'طلبات الإجازة',
    'day-off-request': 'طلب إجازة',
    reports: 'تقارير',
    'med-rep-mark': 'علامات مندوب',
    exams: 'امتحانات',
    salaries: 'الرواتب',
    inventory: 'الجرد',
    'delivery-samples': 'تسليم عينات',
    'create-samples': 'إنشاء عينات',
    statement: 'كشف',
    'products-statement': 'كشف مواد',
    management: 'إدارة',
    staff: 'طاقم العمل',
    customers: 'العملاء',
    'managing-roles': 'إدارة الأدوار',
    'external-entities': 'الجهات الخارجية',
    products: 'المنتجات',
    'regions-management': 'إدارة المناطق',
    'administrative-zone': 'مساحة الإداري',
    'my-visits': 'زياراتي',
    'report': 'التقرير',
    'plan': 'الخطة',
    logout: 'تسجيل الخروج',

    'add-day-off-request': 'إضافة طلب إجازة',
    "minimal-interface": "مواد على وشك النفاذ",
    "warehouses-statistics": "إحصائيات المستودع",
    "daily-report": "الملاحظات اليومية"
}