<template>
    <div>
        <!-- filter -->
        <v-form
        	:disabled="loading"
        	@submit.prevent="needToRestShownItems = true; tempVisits = []; isFiltered = true; filter();"
        >
            <v-row align="center" class="my-0">
                <!-- employee name -->
                <v-col md="2" sm="3" cols="12" class="py-1 pe-1 d-flex">
                    <v-autocomplete
                        v-model="employeeId"
                        label="Employee"
                        :items="employees.filter(c => c.employeeType === userType.medicalRep)"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>

                <!-- entity name -->
                <v-col md="2" sm="3" cols="12" class="py-1 pe-1 d-flex">
                    <v-text-field
                        v-model="entityName"
                        label="Doctor name"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>
                
                <!-- city region filter -->
                <v-col lg="3" md="4" sm="6" cols="12" class="pa-1 ">
                    <v-row class="pa-3">
                        <!-- city name -->
                        <v-col cols="5" class="pa-0 pe-1 d-flex" >
                            <v-autocomplete
                                v-model="cityId"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                label="City"
                                dense
                                hide-details
                                append-icon
                                outlined
                                prepend-icon="mdi-map-marker-multiple"
                            ></v-autocomplete>
                        </v-col>
            
                        <!-- region -->
                        <v-col cols="7" class="pa-0">
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regionsAll.filter(c => !cityId || c.cityId === cityId)"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                label="Regions"
                                dense
                                multiple
                                hide-details
                                clearable
                                @click:clear="cityId = null"
                                outlined
                                :disabled="cityId === null"
                                :search-input.sync="searchRegion"
                                @change="searchRegion = null"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>

                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleRegions"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconRegions }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- month -->
                <v-col md="2" sm="3" cols="12" class="pa-1">
                    <bee-date-input
                        v-model="month"
                        label="Date"
                        dense
                        hide-details
                        changeable-with-arrows
                        input-format-order="year-month-day"
                        input-type="month"
                        append-icon="mdi-menu-right"
                        prepend-icon="mdi-menu-left"
                        @click:append="month = moment(month, 'YYYY-MM').add(1, 'months').format('YYYY-MM')"
                        @click:prepend="month = moment(month, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM')"
                    ></bee-date-input>
                </v-col>

                <!-- specialty -->
                <v-col md="2" sm="3" cols="12" class="pa-1">
                    <v-autocomplete
                        v-model="specialtyId"
                        :items="specialties"
                        item-text="name"
                        item-value="id"
                        label="Specialty"
                        dense
                        clearable
                        hide-details
                        outlined
                    ></v-autocomplete>
                </v-col>

                <v-spacer/>

                <v-col cols="auto">
                    <v-btn
                    	type="submit"
                    	color="primary"
                    	small
                        :disabled="loading || isAllEmpty"
                        :loading="loading"
                    >   
                        search
                    </v-btn>

                    <v-btn
                        @click="resetFilter(); filter()"
                    	color="alert-color white--text"
                    	small
                        :disabled="loading || !isFiltered"
                        :loading="restLoading"
                        class="mx-1"
                    >   
                        reset
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="[]"
            :loading="loading"
            with-columns-divider
            :items-per-page="20"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :server-items-length="serverItemsLength"
            :options-scroll.sync="scrollOptions"
            :data-table-props="{ disableSort: true }"
            fixed-header
            :mobile-breakpoint="0"
            dense
            zebra
            :height="$vuetify.breakpoint.height - 180"
            @pagination-on-scroll:reach-last-row="filter"
        >
            <template
                v-for="header in headers.slice(3)"
            	v-slot:[`item.${header.name}`]="{ header, item, value }"
            >
                <div
                    :key="header.name"
                    :class="[
                        {
                            'success': value === visitStates.done,
                            'red': value === visitStates.missed,
                            'orange': value === visitStates.outOfPlan,
                        },
                        'state-cell'
                    ]"
                >
                    {{item.daysCount ? item.daysCount[header.value] : null}}
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { entityType, userType } from '@/helpers/enums'

export default {
    name: 'VisitFrequencyReport',
    
    data () {
        return {
            isFiltered: false,
            userType,
            loading: false,
            restLoading: false,

            searchRegion: null,
            
            // filter data
            entityName: null,
            cityId: null,
            regionIds: [],
            month: moment().format('YYYY-MM'),
            tableMonth: moment().format('YYYY-MM'),
            specialtyId: null,
            employeeId: null,

            // pagination
            scrollOptions: {},
            serverItemsLength: 0,
            needToRestShownItems: false,
            
            // data
            tempVisits: [],
            visitStates: {
                done: 1,
                outOfPlan: 2,
                missed: 3,
            }
        }
    },

    computed: {
        ...mapState({
            cities: state => state.cities.cities,
            regionsAll: state => state.regions.regionsAll,
            employees: state => state.medicalreps.medicalreps,
            allEntities: state => state.entities.allEntities,
            specialties: state => state.specialties.specialties,
            classification: state => state.workload.classification
        }),

        ...mapGetters({
            getEmployeeById: 'medicalreps/getMedicalRepById',
            getClassificationById: 'workload/getClassificationById'
        }),
        
        headers () {
            const days = [];

            for (let i = 1; i <= moment(this.tableMonth, 'YYYY-MM').daysInMonth(); i++) {
                days.push({
                    text: moment(this.tableMonth + `-${i.toString().padStart(2, '0')}`).format('ddd DD').replace(/ /g, '\n'),
                    name: 'day' + i,
                    value: 'day' + i,
                    class: 'text-pre-wrap',
                    columnClass: 'px-0',
                    align: 'center',
                    width: 30
                })
            }
            return [
                {
                    text: 'Doctor',
                    name: 'entityName',
                    value: 'entityName',
                    columnClass: 'px-2',
                    width: 130
                },
                {
                    text: 'workload',
                    name: 'entityClass',
                    value: 'entityClass',
                    columnClass: 'px-1',
                    align: 'center',
                    width: 30,
                    setValueExpr: val => {
                        const workload = this.getClassificationById(val);
                        return workload && workload.name;
                    }
                },
                {
                    text: 'specialty',
                    name: 'specialtyIds',
                    value: 'specialtyIds',
                    setValueExpr: val => val.length !== 0 ? val.map(c => this.specialties.find(x => x.id === c)?.name).join(', ') : null
                },
                ...days
            ]
        },

        isAllEmpty() {
            return (this.employeeId === null && this.entityName === null && this.cityId === null && this.regionIds === null && this.month === null && this.specialtyId === null)
        },

        selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

        iconRegions () {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        resetFilter() {
            this.restLoading = true
            this.employeeId = null
            this.entityName = null
            this.cityId = null 
            this.regionIds = []
            // this.month = null
            this.specialtyId = null
            this.isFiltered = false
        },
        filter(newScrollOptions, isFirstLoad = false) {
            if (this.loading && !isFirstLoad) return;

            this.tableMonth = this.month;
            let page = 1;
            const { itemsPerPage } = this.scrollOptions;

            if (newScrollOptions) { page = newScrollOptions.page; }

            this.loading = true;
            return Promise.all([
                // this.$store.dispatch('entities/fetchAllBrief', ), 
                this.$store.dispatch('entities/fetchByFilter', {
                    type: entityType.doctor,
                    name: this.entityName,
                    specialtyId: this.specialtyId,
                    regionIds: this.regionIds,
                    page: page,
                    perPage: itemsPerPage || 20,
                    OrderByWorkLoad: true,
                    includeContactInfo: false,
                    includeAddresses: false,
                    OrderByName: true,
                    regionId: [],
                    WithVisitsCount: false,
                    workloadIds: [],
                    employeeId: this.employeeId
                }),
                !this.tempVisits.length
                    ? this.$store.dispatch('visits/fetchVisitsWithoutPagination', {
                        fromDate: moment(this.month, 'YYYY-MM').startOf('months').format('YYYY-MM-DD'),
                        toDate: moment(this.month, 'YYYY-MM').endOf('months').format('YYYY-MM-DD'),
                        employeeId: this.employeeId,
                        regionIds: this.regionIds,
                        specialtyId: this.specialtyId,
                    })
                    : null
            ]).then((data) => {
                if (this.needToRestShownItems) {
                    this.$refs.table.resetShownRows();
                    this.needToRestShownItems = false;
                }

                const items = [];

                const entities = data[0].data;
                const totalItems = data[0].total;

                if (!this.tempVisits.length) this.tempVisits = data[1];
                const visits = this.tempVisits;
                
                // building table items
                entities.forEach(entity => {
                    const entityVisits = visits.filter(c => c.entityId === entity.id);
                    if (entityVisits.length) {
                        const obj = {};
                        const daysKeysObj = {};
                        
                        entityVisits.forEach(visit => {
                            const day = 'day' + moment(visit.createdAt).format('D');

                            // get visits count for each day 
                            if (day in daysKeysObj) {
                                if (!obj.daysCount) obj.daysCount = {};
                                obj.daysCount[day] = (obj.daysCount[day] || 1) + 1;
                            }
                    
                            obj.entityName = visit.entity.name;
                            obj.entityClass = entity.entityAddresses[0] && entity.entityAddresses[0].workloadId;
                            obj.specialtyIds = entity.specialtyIds
                            daysKeysObj[day] = this.visitStates.done;
                        })
                        items.push({ ...obj, ...daysKeysObj });
                    } else {
                        items.push({
                            entityName: entity.name,
                            entityClass: entity.entityAddresses[0] && entity.entityAddresses[0].workloadId,
                            specialtyIds: entity.specialtyIds
                        })
                    }
                });

                this.serverItemsLength = totalItems;
                this.$refs.table.addScrollItems(items);
            }).finally(() => {
                this.loading = false;
                this.restLoading = false
            })
        },

        toggleRegions () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },
        moment
    },

    async created() {
        this.loading = true;

        Promise.all([
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.employees.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.classification.length ? this.$store.dispatch('workload/fetchAll') : null,
            this.filter(null, true),
            !this.regionsAll.length
                ? this.$store.dispatch('regions/fetchAllRegions', {
                    cities: await (
                        this.cities.length ? this.cities : this.$store.dispatch('cities/fetchCityMine')
                    )
                })
                : null,
        ]).finally(() => {
            // if (this.allEntities.length) {
            this.loading = false;
            // };
        })
    },

    metaInfo: {
        title: 'Visit frequency report',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style scoped>
.state-cell {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
</style>
