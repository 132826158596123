<template>
    <div>
        <v-autocomplete
            v-if="0"
            :items="['محمد','إ', 'إبراهيم', 'إسراء', 'أحمد']"
            item-text="name"
            item-value="id"
            :filter="customFilter"
            @input="count()"
        />

        <v-dialog
            v-model="dialogToggle"
            transition="dialog-transition"
            max-width="500"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <div 
                        class="d-flex py-1 "
                        :class="!entityItem.active ? 'success' : 'error lighten-2'"
                    >
                        <v-card-title
                        	class="text-uppercase text-subtitle-1 flex-grow-1 justify-center pa-0 white--text"
                        >
                            Toggle customer account status
                        </v-card-title>
                        <!-- close button -->
                        <v-btn icon @click="dialog.value = false">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>

                    <v-card-text class="text-body-1 pb-0 pt-2">
                        Are you sure you want to
                            <!-- :class=" ? 'success--text' : 'error--text text--lighten-2'" -->
                        <span 
                            class="text--lighten-1" 
                            :class="!entityItem.active ? 'success--text' : 'error--text text--lighten-2'"
                        >
                            {{ !entityItem.active ? 'Activated' : 'Deactivated'}}
                        </span>
                        the selected customer's account?
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            :loading="submitLoading"
                            :color="!entityItem.active ? 'success' : 'red lighten-1'"
                            class="white--text"
                            text
                            @click="submitToggle()"
                        >
                            Confirm
                        </v-btn>
                        <v-btn
                            text
                            :disabled="submitLoading"
                            @click="dialog.value = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
        <!-- doctor dialog -->
        <v-dialog
            transition="dialog-transition"
            :max-width="firstLevel ? 1000 : 700"
            v-model="doctorDialog"
        >
            <v-card>
                <div class="d-flex justify-end py-1 background-title-card ">
                    <v-card-title
                        class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                    >
                        {{ doctorId ? 'Edit doctor' : 'Add new doctor' }}
                    </v-card-title>
                    <!-- close button -->
                    <v-btn
                        icon
                        @click="doctorDialog = false; doctorId = null; $refs.tableAddress.resetEdit(); $refs.doctorContactsTable.resetEdit(); directoryId = null;"
                    >
                        <v-icon color="white">
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>
                <v-card-text class="pt-2 px-3 pb-0">
                    <!-- dialog content -->
                    <v-form 
                        @submit.prevent="submit" ref="doctorForm" :disabled="submitLoading"
                    >
                        <v-row dense class="my-0">
                            <!-- docotor name -->
                            <v-col :sm="firstLevel? 4: 6" cols="12">
                                <v-text-field
                                    label="Doctor Name"
                                    v-model="doctorData.name"
                                    clearable
                                    :rules="rules.required"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <!-- specialty -->
                            <v-col :sm="firstLevel ? 4 : 6 " cols="12">
                                <v-autocomplete
                                    label="Specialty"
                                    v-model="doctorData.specialtyId"
                                    :items="specialties"
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                    :rules="rules.required"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    multiple
                                    :search-input.sync="searchSpecialty"
                                    @change="searchSpecialty = null"
                                ></v-autocomplete>
                            </v-col>
                            <template v-if="firstLevel">
                                <v-col cols="4">
                                    <v-autocomplete 
                                        v-model="doctorData.loyality"
                                        :items="loyalty"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        hide-details
                                        label="Loyalty"
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-autocomplete 
                                        v-model="doctorData.relegion"
                                        :items="religion"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        hide-details
                                        label="Religion"
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-autocomplete
                                        v-model="doctorData.personality" 
                                        :items="personality"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        hide-details
                                        label="Personality"
                                    />
                                </v-col>

                                <v-col>
                                    <bee-date-picker
                                        v-model="doctorData.birthdate"
                                        input-label="Birthdate"
                                        elevation="2"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        min="1940-01-01"
                                    ></bee-date-picker>
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field
                                        v-model="doctorData.weekend"
                                        dense
                                        label="Weekend"
                                        outlined
                                        hide-details
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field 
                                        v-model="doctorData.nearbyPharmaciesOne"
                                        dense
                                        label="Nearby pharmacy 1"
                                        outlined
                                        hide-details
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field 
                                        v-model="doctorData.nearbyPharmaciesTwo"
                                        dense
                                        label="Nearby pharmacy 2"
                                        outlined
                                        hide-details
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field 
                                        v-model="doctorData.worksInCenters"
                                        dense
                                        label="Works in centers"
                                        outlined
                                        hide-details
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field 
                                        v-model="doctorData.pmWorkHours"
                                        dense
                                        label="pm Work Hours"
                                        outlined
                                        hide-details
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field 
                                        v-model="doctorData.amWorkHours"
                                        dense
                                        label="am Work Hours"
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                              
                                <v-col cols="6">
                                    <v-textarea
                                        v-model="doctorData.personalNote"
                                        dense
                                        label="Personal note"
                                        outlined
                                        hide-details
                                        rows="1"   
                                        :auto-grow="true"
                                    />
                                </v-col>

                                <v-col cols="6">
                                    <v-textarea 
                                        v-model="doctorData.doctorHobbies"
                                        dense
                                        label="Doctor Hobbies"
                                        outlined
                                        hide-details
                                        rows="1"
                                        :auto-grow="true"
                                    />
                                </v-col>

                                <v-col cols="12">
                                    <v-textarea 
                                        v-model="doctorData.visitNotes"
                                        dense
                                        label="Visit notes"
                                        outlined
                                        hide-details
                                        rows="1"
                                        :auto-grow="true"
                                    />
                                </v-col>
                            </template>
                        </v-row>
                    </v-form>

                    <!-- table contact information -->
                    <p class="text-body-1 mb-1"> Contact information </p>
                    <bee-handy-smart-table 
                        v-model="doctorTableInputs.contactInfo"
                        ref="doctorContactsTable"
                        :headers="contactInfosHeaders" 
                        :items="doctorData.contactInfo"
                        show-add-row
                        :editable-rows="!submitLoading"
                        :data-table-props="{
                            disableSort: true
                        }"
                        hide-default-footer
                        hide-inputs-details
                        hide-no-data-area
                        dense
                        zebra
                        :disable-input-fields="submitLoading"
                        @keypress.enter="isEditContact ? editDoctorContactRow() : addDoctorContactRow()"
                    >
                        <!-- contact type -->
                        <template v-slot:input.contactType="{ on, attr }">
                            <v-autocomplete 
                                :items="contactInfoTypes"
                                item-text="name"
                                item-value="id"
                                v-on="on"
                                v-bind="attr"
                            />
                        </template>

                        <!-- action column -->
                        <template v-slot:item.actions="{ item, index }">
                            <div class="d-flex">        
                                <tooltip text="Delete contact" top>
                                    <v-btn
                                        icon
                                        small
                                        color="alert-color"
                                        :disabled="submitLoading"
                                        @click="deleteDoctorContactRow({ id: item.id, index})"
                                    >
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            </div>
                        </template>

                        <!-- no-input action -->
                        <template v-slot:no-input.actions>
                            <div class="d-flex justify-center">
                                <tooltip text="Add" top>
                                    <v-btn
                                        icon
                                        small
                                        color="success"
                                        :disabled="isContactTableInputsEmpty"
                                        @click="isEditContact ? editDoctorContactRow() : addDoctorContactRow()"
                                    >
                                        <v-icon>
                                            mdi-check-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            </div>
                        </template>
                    </bee-handy-smart-table>

                    <!-- addresses -->
                    <p class="text-body-1 my-1"> Addresses </p>
                    
                    <bee-handy-smart-table
                        v-model="doctorTableInputs.addresses"
                        ref="tableAddress"
                        :headers="addressesHeaders"
                        :items="doctorData.addresses"
                        show-add-row
                        :editable-rows="!submitLoading"
                        dense
                        zebra
                        :disable-input-fields="submitLoading"
                        hide-inputs-details
                        hide-default-footer
                        hide-no-data-area
                        hot-inputs
                        @change-cityId="
                            doctorTableInputs.addresses.regionId = null;
                        "
                        @keypress.enter="isEditAddress ? editAddressDoctor() : addAddressDoctor()"
                        :data-table-props="{
                            disableSort: true
                        }"
                    >
                        <!-- city id -->
                        <template v-slot:input.cityId="{ on, attr }" >
                            <v-autocomplete
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                v-on="on"
                                v-bind="attr"
                            ></v-autocomplete>
                        </template>

                        <!-- region -->
                        <template v-slot:input.regionId="{ on, attr }" >
                            <!-- {{ regionsAll.filter(c => doctorTableInputs.addresses.cityId === c.cityId)}} -->
                            <v-autocomplete
                                :items="doctorId ? 
                                    regionsAll.filter(c => doctorTableInputs.addresses.cityId === c.cityId) : regionsAll.filter(c => doctorTableInputs.addresses.cityId === c.cityId)"
                                item-text="name"
                                item-value="id"
                                v-on="on"
                                v-bind="attr"
                                :loading="regionsLoading"
                                :disabled="!doctorTableInputs.addresses.cityId || regionsLoading"
                            ></v-autocomplete>
                        </template>

                        <!-- workload -->
                        <template v-slot:input.workload="{ on, attr }" >
                            <v-autocomplete
                                :items="classification"
                                item-text="name"
                                item-value="id"
                                v-on="on"
                                v-bind="attr"
                            ></v-autocomplete>
                        </template>

                        <!-- action column -->
                        <template v-slot:item.actions="{ item, index }">
                            <div class="d-flex">
                                <tooltip text="Edit doctor" top>
                                    <v-btn
                                        icon
                                        small
                                        color="edit-color"
                                        :disabled="submitLoading"
                                        @click="() => {
                                            $refs.tableAddress.activeEditForRow(index);
                                            editAddressRowIndex = index;
                                            isEditAddress = true;
                                            $nextTick(() => {
                                                $refs.tableAddress.resetInputsFocus()
                                            })
                                        }"
                                    >
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                                
                                <tooltip text="Delete" top>
                                    <v-btn
                                        icon
                                        small
                                        :disabled="submitLoading"
                                        @click="deleteDoctorAddressRow({ id: item.id, index })"
                                        color="alert-color"
                                    >
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            </div>
                        </template>

                        <!-- no-input action -->
                        <template v-slot:no-input.actions>
                            <div class="d-flex justify-center">
                                <tooltip text="Add" top>
                                    <v-btn
                                        icon
                                        small
                                        color="success"
                                        :disabled="isAddressTableInputsEmpty"
                                        @click="isEditAddress ? editAddressDoctor() : addAddressDoctor()"
                                    >
                                        <v-icon>
                                            mdi-check-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            </div>
                        </template>
                    </bee-handy-smart-table>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        :loading="submitLoading"
                        :disabled="submitLoading"
                        color="success white--text"
                        text
                        @click="submit"
                    >
                        save
                    </v-btn>
                    <v-btn
                        @click="doctorDialog = false; doctorId = null; directoryId = null; $refs.tableAddress.resetEdit(); $refs.doctorContactsTable.resetEdit();"
                        text
                    >
                        cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- directory dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="550"
            ref="directoryDialog"
            v-model="directoryDialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="directoryForm" :disabled="submitLoading">
                    <v-card>
                        <div class="d-flex primary py-1">
                            <v-card-title class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text">
                                {{ (!directoryId ? 'Add' : 'Edit') + ' ' + currentTabLabel }}
                            </v-card-title>
                            <!-- close button -->
                            <v-btn icon @click="dialog.value = false">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-card-text class="pt-2 px-3 pb-0">
                            <!-- dialog content -->
                            <v-row dense class="ma-0">
                                <!-- name -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        :label="currentTabLabel + ' name'"
                                        v-model="directoryData.name"
                                        clearable
                                        :rules="rules.required"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- workload -->
                                <v-col sm="6" cols="12">
                                    <v-autocomplete
                                        label="Workload"
                                        v-model="directoryData.addresses[0].workloadId"
                                        :items="classification"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>
                                
                                <v-col v-if="tab === 1 && firstLevel" cols="12">
                                    <v-textarea 
                                        v-model="directoryData.pharmacistInfo"
                                        dense
                                        label="Pharmacist Info"
                                        outlined
                                        hide-details
                                        rows="-1"
                                        :auto-grow="true"
                                    />
                                </v-col>

                                <v-col v-if="tab === 1 && firstLevel" cols="12">
                                    <v-textarea 
                                        v-model="directoryData.pharmacyInfo"
                                        dense
                                        label="Pharmacy info"
                                        outlined
                                        hide-details
                                        rows="-1"
                                        :auto-grow="true"
                                    />
                                </v-col>

                                <!-- doctor name in this center -->
                                <v-col v-if="tab === 2" cols="12">
                                    <v-text-field
                                        v-model="directoryData.doctorNames"
                                        label="Resident doctors"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    />
                                </v-col>

                                <!-- specailty doctor in this center -->
                                <v-col v-if="tab === 2" cols="12">
                                    <v-autocomplete
                                        v-model="directoryData.specialtyId"
                                        :items="specialties"
                                        item-text="name"
                                        item-value="id"
                                        label="Center's specialty"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        multiple
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index < 3" class="me-1">{{ item.name + ', ' }}</span>
                                            <span
                                                v-if="index === 3"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ directoryData.specialtyId.length - 3 }})
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col sm="12" cols="12">
                                    <v-text-field
                                    	v-model="directoryData.addresses[0].bestTimeToVisit"
                                    	label="Best time to visit"
                                    	outlined
                                    	dense
                                    	hide-details
                                    />
                                </v-col>

                                <v-col v-if="tab === 2" cols="12">
                                    <bee-handy-smart-table
                                        ref="tableContactCenter"
                                        v-model="directoryTableInputs.contactInfo"
                                        :items="directoryData.contactInfo"
                                        :headers="headersConCenter"
                                        show-add-row
                                        dense
                                        zebra
                                        hide-default-footer
                                        :items-per-page="-1"
                                    >
                                        <!-- contact type -->
                                        <template v-slot:input.contactType="{ on, attr }">
                                            <v-autocomplete 
                                                :items="contactInfoTypes"
                                                item-text="name"
                                                item-value="id"
                                                v-on="on"
                                                v-bind="attr"
                                            />
                                        </template>

                                        <template v-slot:item.actions="{ item, index }">
                                            <div class="d-flex">        
                                                <tooltip text="Delete contact" top>
                                                    <v-btn
                                                        icon
                                                        small
                                                        color="alert-color"
                                                        :disabled="submitLoading"
                                                        @click="deleteCenterContactRow({ id: item.id, index})"
                                                    >
                                                        <v-icon>
                                                            mdi-delete
                                                        </v-icon>
                                                    </v-btn>    
                                                </tooltip>
                                            </div>
                                        </template>

                                        <!-- no-input action -->
                                        <template v-slot:no-input.actions>
                                            <div class="d-flex justify-center">
                                                <tooltip text="Add" top>
                                                    <v-btn
                                                        icon
                                                        small
                                                        color="success"
                                                        :disabled="isContactTableCenterInputsEmpty"
                                                        @click="isEditContact ? editDirectory() : addCenterContactRow()"
                                                    >
                                                        <v-icon>
                                                            mdi-check-circle-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </tooltip>
                                            </div>
                                        </template>
                                    </bee-handy-smart-table>
                                </v-col>

                                <!-- phone number -->
                                <v-col v-if="tab === 1" sm="6" cols="12">
                                    <v-text-field
                                        label="Phone number"
                                        v-model="directoryData.contactInfo[0].value"
                                        clearable
                                        type="number"
                                        min='0'   
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Telephone number -->
                                <v-col v-if="tab === 1" sm="6" cols="12">
                                    <v-text-field
                                        label="Telephone number"
                                        v-model="directoryData.contactInfo[1].value"
                                        clearable
                                        type="number"
                                        min='0'
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- city -->
                                <v-col sm="6" cols="12">
                                    <v-autocomplete
                                        v-model="directoryData.cityId"
                                        label="City"
                                        :items="cities"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        @change="fetchRegionsDueCity"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- region -->
                                <v-col sm="6" cols="12">
                                    <v-autocomplete
                                        label="Region"
                                        v-model="directoryData.addresses[0].regionId"
                                        :items="regions"
                                        item-text="name"
                                        item-value="id"
                                        :loading="regionsLoading"
                                        :disabled="!directoryData.cityId || regionsLoading"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- full address -->
                                <v-col cols="12">
                                    <v-text-field
                                        label="Full address"
                                        v-model="directoryData.addresses[0].address"
                                        clearable
                                        :rules="rules.required"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                :loading="submitLoading"
                                :disabled="submitLoading"
                                color="success white--text"
                                elevation="0"
                                text
                            >
                                save
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                elevation="0"
                                text
                            >
                                cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- delete dialog -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="needToResetItems = true; filter();"
            success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> {{currentTabLabel}} data?
        </delete-dialog>

        <!-- merge dialog -->
        <v-dialog
            v-model="mergeDialog"
            transition="dialog-transition"
            persistent
            max-width="350"
        >
            <v-card>
                <div class="d-flex justify-end py-1 background-title-card ">
                    <v-card-title class="text-uppercase text-subtitle-1 flex-grow-1 justify-center pa-0 white--text">
                        Merge Customer  
                    </v-card-title>

                    <!-- close button -->
                    <v-btn icon @click="mergeDialog = false">
                        <v-icon color="white">
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>
                <v-card-text class="pt-2 px-3 pb-0">
                    <v-row dense class="ma-0">
                        <p>
                            Do you want to merge the customer with another customer?
                        </p>
                        <v-col align-self="center" class="pa-1">
                            <v-autocomplete
                                v-model="newEntity" 
                                :items="accountsCustomer"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                hide-details
                                label="Customer"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn
                        type="submit"
                        :loading="submitLoading"
                        color="success white--text"
                        text
                        @click="merge()"
                    >
                        save
                    </v-btn>
                    <!-- $refs.deleteDialog.setAdditionalData({ cityId: cityItemId }) -->
                    <v-btn
                        @click="
                            $refs.deleteDialog.activeDeleteDialog(entryItem.id, 'entities')
                        "
                        text
                    >
                        cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row>
			<v-col cols="12" sm="12" md="6" lg="5" class="pt-0">
				<!-- tabs -->
				<v-tabs
					v-model="tab"
					@change="getEntityByTab()"
				>
					<v-tab
						v-for="title in tabs.titles"
						:key="title"
						:disabled="loading"
						class="text-capitalize"
					>
						{{ title }}
					</v-tab>
				</v-tabs>
            	<v-divider/>
			</v-col>
            <v-spacer v-if="tab !== 0 " />
			<v-col 
                cols="12" 
                sm="12" 
                :md="tab === 0 ? 9 : 2" 
                :lg="tab === 0 ? 7 : 2" 
                :align-self="$vuetify.breakpoint.mdAndUp ? 'end' : 'start'" 
                class="pt-0 d-flex" 
                :class="{
                    'd-flex flex-wrap': $vuetify.breakpoint.smAndDown,
                    'justify-end': $vuetify.breakpoint.lgAndUp
                }"
            >
                <!-- doctor-dialog activator button -->
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.Entities === roleName.action"
                    small
                    depressed
                    color="light-blue white--text"
                    class="my-1"
                    @click="
                        tab === 0
                            ? (() => { resetDoctorInputs(); doctorDialog = true; isAdd = true })()
                            : (() => {
                                resetDirectoryInputs();
                                directoryData.entityType = entityTypeForCurrentTab;
                                directoryDialog = true;
                            })()
                    "
                >
                    <v-icon class="me-1">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{ 'new ' + tabs.labels[tab] }}
                </v-btn>
            
                <!-- modification requests -->
                <v-btn
                    v-show="tab===0"
                    v-if="isSuperAdmin || permissionsUser.EntityModificationRequests !== roleName.nothing"
                    :to="{ name: 'modification-requests'}"
                    depressed
                    small
                    color="light-blue white--text"
                    class="mx-1 my-1"
                >
                    modification requests
                </v-btn>

                <!-- links to other pages -->
                <!-- specializations-management -->
                <v-btn
                    v-if="tab === 0 && (isSuperAdmin || permissionsUser.Entities === roleName.action)"
                    :to="{ name: 'specializations-management' }"
                    depressed
                    small
                    color="light-blue white--text"
                    class="mx-1 my-1"
                >
                    <v-icon small class="me-1">mdi-cog</v-icon>
                    specializations
                </v-btn>

                <!-- classification-management -->
                <v-btn
                    v-if="tab === 0 && (isSuperAdmin || permissionsUser.Entities === roleName.action)"
                    :to="{ name: 'classification-management' }"
                    depressed
                    small
                    color="light-blue white--text"
                    class="mx-1 my-1" 
                >
                    <v-icon small class="me-1">mdi-cog</v-icon>
                    classification
                </v-btn>
			</v-col>
        </v-row>

        <!-- filter -->
        <v-row class="my-1 ms-1" align="center">
            <!-- sector -->
            <v-col v-if="0" md="3" cols="12" class="pa-1 d-flex">
                <v-autocomplete
                    v-model="sectorId"
                    :items="sectorsMine"
                    item-text="name"
                    item-value="id"
                    label="Areas"
                    dense
                    clearable
                    hide-details
                    :loading="regionsLoading"
                    :disabled="loading || regionsLoading || cityId === []"
                    @click:clear="cityId = []; regionIds = []; fetchAllCities()"
                    @change="getCitiesFilter()"
                    outlined
                >
                </v-autocomplete>
            </v-col>
            <!-- city region filter -->
            <v-col lg="3" md="5" sm="7" cols="12" class="pa-1">
                <v-row class="pa-3 pa-3 d-flex justify-space-between">
                    <!-- cities -->
                    <v-col cols="5" class="pa-0 pe-1 d-flex">
                        <v-icon class="me-2" size="28"> mdi-map-marker-multiple </v-icon>
                        <v-autocomplete
                            v-model="cityId"
                            :items="!sectorId ? cities : citiesBySectorFilter"
                            item-text="name"
                            item-value="id"
                            label="City"
                            dense
                            hide-details
                            append-icon
                            @change="setRegions(); searchCityId = null"
                            :disabled="loading"
                            outlined
                            multiple
                            :search-input.sync="searchCityId"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="toggleCities"
                                >
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ iconCities }}
                                        </v-icon>
                                    </v-list-item-action>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Select All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>

                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                >
                                    (+{{ cityId.length - 1 }})
                                </span>
                            </template>   
                        </v-autocomplete>
                    </v-col>

                    <!-- region -->
                    <v-col cols="7" class="pa-0">
                        <v-autocomplete
                            v-model="regionIds"
                            :items="regionsAll.filter(c => cityId.includes(c.cityId))"
                            item-text="name"
                            item-value="id"
                            label="Regions"
                            dense
                            multiple
                            clearable
                            hide-details
                            :loading="regionsLoading"
                            :disabled="loading || regionsLoading || cityId === []"
                            outlined
                            :search-input.sync="searchRegionId"
                            @change="searchRegionId = null"
                        >
                            <template v-slot:prepend-item v-if="regions">
                                <v-list-item ripple @click="toggleReignsFilterItems">
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Select All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                >
                                    (+{{ regionIds.length - 1 }})
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>

            <!-- name of -->
            <v-col md="2" sm="4" cols="12" class="pa-1 d-flex">
                <v-icon v-if="tabs.icons[tab]"> {{ tabs.icons[tab] }} </v-icon>
                <v-text-field
                    v-model="name"
                    :label="tabs.labels[tab]"
                    dense
                    hide-details
                    clearable
                    :disabled="loading"
                    outlined
                ></v-text-field>
            </v-col>

            <!-- specialty -->
            <v-col md="2" sm="3" cols="12" v-if="tab!==1" class="pa-1">
                <v-autocomplete
                    v-model="specialtyId"
                    :items="specialties"
                    item-text="name"
                    item-value="id"
                    label="Specialty"
                    dense
                    clearable
                    hide-details
                    :disabled="loading"
                    outlined
                ></v-autocomplete>
            </v-col>

            <!-- workload -->
            <v-col sm="3" md="2" cols="12" class="pa-1 workload">
                <v-autocomplete
                    v-model="workload"
                    :items="classification"
                    item-text="name"
                    item-value="id"
                    label="Workload"
                    dense
                    clearable
                    hide-details
                    :disabled="loading"
                    outlined
                    multiple
                ></v-autocomplete>
            </v-col>
            
            <v-col cols="auto" class="d-flex pa-1">
                <v-btn-toggle
                    v-model.number="sort"
                    mandatory
                    dense
                    class="mb-1"
                    color="primary"
                    @change="needToResetItems = true; isFiltered = true; filter()"
                >
                    <v-btn :disabled="loading">Sort by name</v-btn>
                    <v-btn :disabled="loading">Sort by classification</v-btn>
                </v-btn-toggle>
            </v-col>

            <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

            <v-col cols="auto" class="px-0 pt-0 px-md-3">
                <v-btn
                    color="success"
                    class="px-sm-4 mx-2"
                    :loading="loading"
                    :disabled="isAllFieldsEmpty || loading"
                    @click="needToResetItems = true; isFiltered = true; filter();"
                    small
                >
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>

                <v-btn
                    color="alert-color white--text"
                    class="px-sm-8"
                    :disabled="!isFiltered || loading"
                    @click="needToResetItems= true; clearFilter(); filter()"
                    small
                >
                    Reset
                </v-btn>
                <v-divider vertical />

                <v-btn
                    small
                    color="green lighten-1 white--text"
                    @click="ImportExcel"
                    class="mx-1"
                    :loading="excelLoading"
                    :disabled="loading || excelLoading"
                >
                    <v-icon> mdi-file-table-outline </v-icon>
                    Export excel
                </v-btn>
            </v-col>
        </v-row>

        <!-- table -->
        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="!loading ? array : []"
            fixed-header
            :height="$vuetify.breakpoint.height - 240"
            :loading="loading"
            dense
            zebra
            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="filter"
            :data-table-props="{
                disableSort: true
            }"
        >
            <!-- action column -->
            <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                    <tooltip text="Activated / Deactivated" top>
                        <v-switch 
                            v-model="item.active"
                            class="ma-0 py-0"
                            hide-details
                            readonly
                            dense
                            @click="entityId = item.id; entityItem = item; dialogToggle = true"
                        ></v-switch>
                    </tooltip>
                    <tooltip text="Edit doctor" top>
                        <v-btn
                          icon
                          small
                          @click="item = item; tab === 0 ? editDoctor(item) : editDirectory(item);"
                          color="edit-color"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete customer" top>
                        <!-- @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'entities')" -->
                        <v-btn
                        	icon
                        	small
                        	color="alert-color"
                        	@click="entryItem = item; mergeDialog = true"
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import Tooltip from '@/components/Tooltip'
import rules from '@/validation rules'
import { mapGetters, mapState } from 'vuex'
import { contactInfoType, contactInfoTypes, entityType, roleName, loyalty, religion, personality } from '@/helpers/enums'
import { dig, findClassification } from '@/helpers/functions'
import { firstLevel } from '@/configs/global.config'

export default {
    name: 'Customers',

    components:{
        Tooltip,
        DeleteDialog,
    },

    watch: {
        doctorDialog(val) {
            if (val === false) {
                this.isAdd = false
            }
            if (this.isAdd && !this.firstLevel) {
                this.doctorData.contactInfo = [
                    {
                        contactInfoType: 0, value: ''
                    },
                    {
                        contactInfoType: 0, value: ''
                    },
                    {
                        contactInfoType: 1, value: ''
                    },
                    {
                        contactInfoType: 2, value: ''
                    }
                ]
            } else {
                this.doctorData.contactInfo = []
            }
        },

        mergeDialog (val) {
            if (!val) {
                this.newEntity = null
            }
        }

        // 'this.firstLevel'(val) {
           
        // }
    },
    data() {
        return {
            excelLoading: false,
            searchSpecialty: null,
            searchCityId: null,
            searchRegionId: null,
            array: [],
            isAdd: false,
            roleName,
            tab: 0,
            tabs: {
                titles:['doctors', 'pharmacies', 'centers'],
                labels: ['Doctor', 'Pharmacy', 'Center'],
                icons: ['mdi-doctor', '', '']
            },

            // Filter data
            name: null,
            workload: [],
            specialtyId: null,
            cityId: [],
            regionIds: [],

            // helpers
            loading: false,
            submitLoading: false,
            regionsLoading: false,
            isFiltered: false,
            contactInfoTypes,
            rules,

            // pagination
            scrollOptions: {},
            serverItemsLength: 0,
            needToResetItems: false,

            // Data
            directoryId: null,
            directoryData: {
                cityId: null,
                name: null,
                entityType : null, // 0 for center and 2 for pharmacy type in API
                specialtyId: [],
                contactInfo: [
                    { contactInfoType: contactInfoType.phone, value: null },
                    { contactInfoType: contactInfoType.landline, value: null }
                ],
                // each contactInfo should be { contactInfoType: number, value: string },
                addresses: [{ address: null, regionId: null, workloadId: null, bestTimeToVisit: null }],
                // each address should be { address: string, regionId: string, workload: number }
                // bestTimeToVisit: null,
                table: {},
                pharmacistInfo: null,
                pharmacyInfo: null
            },
            
            // doctor dialog info
            isEditContact: null,
            editContactRowIndex: null,
            
            isEditAddress: null,
            editAddressRowIndex: null,
            doctorTableInputs: {
                contactInfo: {
                    contactValue: null
                },
                addresses: {},
            },
            doctorId: null,
            
            addresses:[],
            numAddresses: 0,

            contactInfos:[],
            numContact: 0,

            doctorData: {
                name: null,
                entityType : 1, // 1 for doctor type in API
                specialtyId: [],
                contactInfo: [], // each address should be { contactInfoType: number, value: string },
                addresses: [], // each address should be { city: string, address: string, regionId: string, workload: number }
                loyality: null,
                relegion: null,
                personality: null,
                birthdate: null,
                weekend: null,
                personalNote: null,
                doctorHobbies: null,
                nearbyPharmaciesOne: null,
                nearbyPharmaciesTwo: null,
                visitNotes: null,
                worksInCenters: null,
                pmWorkHours: null,
                amWorkHours: null,
                pharmacyInfo: null
            },
            isEditItem: false,
            rowEditIndex: null,
            addressesDoctor: [],
            isEditItemAddress: false,
            entries: [],
            item: null,
            doctorDialog: false,
            sectorId: null,
            directoryDialog: false,
            directoryTableInputs: {
                contactInfo: {
                    contactValue: null
                },
                addresses: {},
            },
            sort: 1,
            loyalty, 
            religion,
            personality,
            firstLevel,
            mergeDialog: false,
            accountsCustomer: [],
            newEntity: null,
            active: null,
            entryItem: null,
            dialogToggle: false,
            entityId: null,
            entityItem: null
        }
    },

    computed:{
        ...mapState({
            cities: state => state.cities.cities,
            regions: state => state.regions.regions,
            regionsAll: state => state.regions.regionsAll,
            specialties: state => state.specialties.specialties,
            entities: state => state.entities.entitiesAll,
            classification: state => state.workload.classification,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            sectorCities: state => state.sectors.sectorCities.map(c => c.sectors[0]),
            sectors: state => state.sectors.sectors,
            sectorsMine: state => state.sectors.sectorsMine,
            citiesBySectorFilter: state => state.sectors.citiesBySectorFilter
        }),

        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            getSpecialtyById: 'specialties/getSpecialtyById',
            getEntityById: 'entities/getEntityById',
            getCityById: 'cities/getCityById',
            getRegionAllById: 'regions/getRegionAllById',
            getClassificationById: 'workload/getClassificationById'
        }),

        // contact
        isContactTableInputsEmpty() {
            for (const key in this.doctorTableInputs.contactInfo) {
                if (this.doctorTableInputs.contactInfo[key] !== null) return false;
            }
            return true;
        },

        isContactTableCenterInputsEmpty() {
            for (const key in this.directoryTableInputs.contactInfo) {
                if (this.directoryTableInputs.contactInfo[key] !== null) return false;
            }
            return true;
        },
        contactInfosHeaders() {
            return [
                {
                    text: 'Contact type',
                    name: 'contactType',
                    value: 'contactInfoType',
                    setValueExpr: val => contactInfoTypes.find(c => c.id === val)?.name,
                    inputRules: this.isContactTableInputsEmpty ? [] : rules.requiredAsNumber,
                    noNotAvailableText: true,
                    writable: !this.loading
                }, 
                {
                    text: 'Contact',
                    name: 'contactValue',
                    value: 'value',
                    inputRules: this.isContactTableInputsEmpty ? [] : rules.required,
                    writable: !this.loading,
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    align: 'center',
                    noInput: true
                }
            ]
        },

        // addresses
        isAddressTableInputsEmpty() {
            for (const key in this.doctorTableInputs.addresses) {
                if (this.doctorTableInputs.addresses[key]) return false;
            }
            return true
        },
        addressesHeaders() {
            return [
                {
                    text: 'City',
                    name: 'cityId',
                    value: 'cityId',
                    setValueExpr: val => this.getCityById(val)?.name,
                    inputRules: this.isAddressTableInputsEmpty ? [] : rules.required,
                    noNotAvailableText: true,
                },
                {
                    text: 'Region', 
                    name: 'regionId',
                    value: 'regionId',
                    setValueExpr: val => this.getRegionAllById(val)?.name,
                    inputRules: this.isAddressTableInputsEmpty ? [] : rules.required,
                    noNotAvailableText: true,
                },
                {
                    text: 'Workload',
                    name: 'workload',
                    value: 'workloadId',
                    setValueExpr: val => this.getClassificationById(val)?.name
                },
                {   
                    text: 'Visit time',
                    name: 'visitTime',
                    value: 'visitTime'                    
                },
                {   
                    text: 'Best time to visit',
                    name: 'bestTimeToVisit',
                    value: 'bestTimeToVisit'                    
                },
                {
                    text: 'Full address',
                    name: 'address',
                    value: 'address',
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                }
            ]
        },
        
        headers() {
            const headers = [
                {
                    text: this.currentTabLabel + ' name', 
                    align: 'start', 
                    value: 'name', 
                },
                { 
                    text: 'Classification', 
                    value: 'entityAddresses', 
                    setValueExpr: val => this.getClassificationById(val[0]?.workloadId)?.name || '-',
                    align: 'start', 
                },
                { 
                    text: 'City',
                    value: 'entityAddresses', 
                    name: 'city',
                    setValueExpr: val => {
                        const cityName = val.length ? this.getCityById(this.getRegionAllById(val[0]?.regionId)?.cityId)?.name : null
                        return cityName;
                    },
                    align: 'start',
                },
                { 
                    text: 'Region', 
                    value: 'entityAddresses', 
                    setValueExpr: val => val.length !== 0 ? this.getRegionAllById(val[0].regionId)?.name : '-',
                    align: 'start', 
                },
                { 
                    text: 'Mobile Number', 
                    value: 'contactInfos',  
                    setValueExpr: val => {
                        if (val.length === 0) return '-';
                        const phone = val.filter(c => c.contactInfoType === 0);
                        return phone.length === 0 ? null : phone[0].value;
                    },
                    align: 'center',
                },   
                { 
                    text: 'Phone Number', 
                    value: 'contactInfos', 
                    setValueExpr: val => {
                        if (val.length === 0) return '-';
                        const telephone = val.filter(c => c.contactInfoType === 1);
                        return telephone.length === 0 ? null : telephone[0].value;
                    },
                    align: 'center', 
                } 
            ]

            if (this.isSuperAdmin || this.permissionsUser.Entities === roleName.action){
                headers.push(
                    { 
                        text: 'Actions', 
                        name: 'actions', 
                        value: 'actions', 
                        align: 'center', 
                        class: 'text-body-2 font-weight-bold'
                    }
                );
            }

            if ((this.tab === 0 && !this.loading) || (this.tab === 2 && !this.loading)) {
                headers.splice(2, 0, { 
                    text: 'Specialty', 
                    value: 'specialtyIds', 
                    setValueExpr: val => val.map(c => this.dig('getSpecialtyById', c, 'name')).join(', '),
                    align: 'start', 
                })
            }

            const sharedClass = 'text-body-2 font-weight-bold';
            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
            }
            return headers
        },

        // helpers
        isAllFieldsEmpty(){
            return !this.name && this.workload.length === 0 && !this.specialtyId && !this.regionIds.length;
        },

        entityTypeForCurrentTab(){
            switch (this.tab) {
                case 0: return entityType.doctor;
                case 1: return entityType.pharmacy;
                case 2: return entityType.center;
                default: return null;
            }
        },

        currentTabLabel() {
            return this.tabs.labels[this.tab]
        },

        // selectors
        selectedAllSectors () {
            return this.regionIds?.length === this.regions?.length;
        },
        selectedSomeSectors () {
            return this.regionIds.length > 0 && !this.selectedAllSectors;
        },
        icon () {
            if (this.selectedAllSectors) return 'mdi-close-box'
            if (this.selectedSomeSectors) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },

        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        headersConCenter () {
            const center = [
                {
                    text: 'Contact type',
                    name: 'contactType',
                    value: 'contactInfoType',
                    setValueExpr: val => contactInfoTypes.find(c => c.id === val)?.name,
                    inputRules: this.isContactTableCenterInputsEmpty ? [] : rules.requiredAsNumber,
                    noNotAvailableText: true,
                    writable: !this.loading
                }, 
                {
                    text: 'Contact',
                    name: 'contactValue',
                    value: 'value',
                    inputRules: this.isContactTableCenterInputsEmpty ? [] : rules.required,
                    writable: !this.loading,
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    align: 'center',
                    noInput: true
                }
            ]
            return center
        },

        selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        }
    },

    methods: {
        customFilter(item, queryText, itemText) {
            const text = [...queryText]
            if (text.length === 1) {
                return item.includes(queryText) || item.startsWith('إ') || item.startsWith('أ')
            } else if (text[0] === 'أ' || text[0] === 'إ' || text[0] === 'ا') {
                return item.includes(queryText.substr(1)) && (item.startsWith('ا') || item.startsWith('إ') || item.startsWith('أ'))
            } else {
                return item.includes(queryText)
            }
        },
        // Find data functions
        dig,

        fetchRegionsDueCity(cityId){
            this.regionIds = [];
            if (!cityId) return;
            
            this.regionsLoading = true;
            this.$store.dispatch('regions/fetchAll', {
                cityId: cityId
            })
            .finally(() => {
                this.regionsLoading = false
            })
        },

        setRegions () {
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

        findClassification(addresses){
            if (addresses.length === 0) return null;
            return findClassification(addresses[0].workload);
        },

        // filter functions
        filter(newOptionsScroll) {
            this.loading = true;
            let page = 1;

            const { itemsPerPage } = this.scrollOptions;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }

            return this.$store.dispatch('entities/fetchAllEntities', {
                type: this.entityTypeForCurrentTab,
                name: this.name,
                workloadIds: this.workload,
                specialtyId: this.specialtyId,
                regionIds: this.regionIds,
                page: page, 
                perPage: itemsPerPage,
                nonDeleted: true,
                OrderByWorkLoad: this.sort === 1,
                OrderByName: this.sort === 0,
                WithVisitsCount: false
            }).then(({ data, total }) => {
                if (this.needToResetItems) { 
                    this.$refs.table.resetShownRows();
                    this.needToResetItems = false;
                }
                this.$refs.table.addScrollItems(data);
                this.array = this.array.concat(data)
                this.serverItemsLength = total;
            }).finally(() => {
                this.loading = false;
                this.mergeDialog = false
            })
        },

        clearFilter(){
            this.name = null;
            this.cityId = [];
            this.regionIds = [];
            this.specialtyId = null
            this.sectorId = null
            this.workload = []
            this.sort = 0

            this.isFiltered = false;
        },

        toggleReignsFilterItems () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } else {
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

        // doctor dialog tables
        // contact
        addDoctorContactRow() {
            if (this.$refs.doctorContactsTable.validate() && !this.isContactTableInputsEmpty) {
                this.doctorData.contactInfo.push({
                    id: ++this.numContact,
                    contactInfoType: this.doctorTableInputs.contactInfo.contactType,
                    value: this.doctorTableInputs.contactInfo.contactValue
                });
                this.$refs.doctorContactsTable.resetInputs();
                this.$refs.doctorContactsTable.resetInputsFocus();
            }
        },

        editDoctorContactRow() {
			if (this.$refs.doctorContactsTable.validate()) {
				this.doctorData.contactInfo[this.editContactRowIndex].contactInfoType =
                    this.doctorTableInputs.contactInfo.contactType;
				this.doctorData.contactInfo[this.editContactRowIndex].value =
                    this.doctorTableInputs.contactInfo.contactValue
				this.$refs.doctorContactsTable.resetEdit();
				this.isEditContact = false;
				
				this.$nextTick(() => {
					this.$refs.doctorContactsTable.resetInputs();
					this.$refs.doctorContactsTable.resetInputsFocus();
				})
			}
		},

        deleteDoctorContactRow(rowData) {
			this.isEditContact = false;
			this.doctorData.contactInfo.splice(rowData.index, 1);
            this.numContact--;

            for (let i = 0; i < this.doctorData.contactInfo.length; i++) {
                if (this.doctorData.contactInfo[i].id > rowData.id) {
                    this.doctorData.contactInfo[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.doctorContactsTable.resetInputs();
				this.$refs.doctorContactsTable.resetInputsFocus()
			})
		},

        deleteCenterContactRow(rowData) {
            this.$refs.tableContactCenter.resetEdit();
			this.isEditContact = false;
			this.directoryData.contactInfo.splice(rowData.index, 1);
            this.numContact--;

            for (let i = 0; i < this.directoryData.contactInfo.length; i++) {
                if (this.directoryData.contactInfo[i].id > rowData.id) {
                    this.directoryData.contactInfo[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.tableContactCenter.resetInputs();
				this.$refs.tableContactCenter.resetInputsFocus()
			})
		},

        // address
        addAddressDoctor() {
            if (this.$refs.tableAddress.validate() && !this.isAddressTableInputsEmpty) {
                this.doctorData.addresses.push({
                    id: ++this.numAddresses,
                    cityId: this.doctorTableInputs.addresses.cityId,
                    regionId: this.doctorTableInputs.addresses.regionId,
                    visitTime: this.doctorTableInputs.addresses.visitTime,
                    bestTimeToVisit: this.doctorTableInputs.addresses.bestTimeToVisit,
                    address: this.doctorTableInputs.addresses.address === null ? '-' : this.doctorTableInputs.addresses.address,
                    workloadId: this.doctorTableInputs.addresses.workload,
                })
                this.$refs.tableAddress.resetInputs();
                this.$refs.tableAddress.resetInputsFocus();
            }
        },

        editAddressDoctor() {
            if (this.$refs.tableAddress.validate()) {
                this.doctorData.addresses[this.editAddressRowIndex].regionId = this.doctorTableInputs.addresses.regionId;
                this.doctorData.addresses[this.editAddressRowIndex].address = this.doctorTableInputs.addresses.address ? this.doctorTableInputs.addresses.address : '-';
                this.doctorData.addresses[this.editAddressRowIndex].workloadId = this.doctorTableInputs.addresses.workload;
                this.doctorData.addresses[this.editAddressRowIndex].visitTime = this.doctorTableInputs.addresses.visitTime;
                this.doctorData.addresses[this.editAddressRowIndex].bestTimeToVisit = this.doctorTableInputs.addresses.bestTimeToVisit;
                
                this.$refs.tableAddress.resetEdit();
                this.isEditAddress = false;
                this.$nextTick(() => {
                    this.$refs.tableAddress.resetInputs();
					this.$refs.tableAddress.resetInputsFocus()
                })
			} 
        },

        deleteDoctorAddressRow(rowData) {
            this.$refs.tableAddress.resetEdit();
			this.isEditAddress = false;
			this.doctorData.addresses.splice(rowData.index, 1);
            this.numContact--;

            for (let i = 0; i < this.doctorData.addresses.length; i++) {
                if (this.doctorData.addresses[i].id > rowData.id) {
                    this.doctorData.addresses[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.tableAddress.resetInputs();
				this.$refs.tableAddress.resetInputsFocus()
			})
		},

        // edit doctor
        resetDoctorInputs(){
            if (this.$refs.doctorForm) this.$refs.doctorForm.resetValidation();

            // reset counters
            this.numContact = 0;
            this.doctorData.contactInfo = [];

            this.numAddresses = 0;
            this.doctorData.addresses = [];

            // reset doctor data
            this.doctorData.name = null;
            this.doctorData.entityType = 1;
            this.doctorData.specialtyId = [];
            this.doctorData.contactInfo = [];
            this.doctorData.addresses = [];

            this.doctorData.loyality = null
            this.doctorData.relegion = null
            this.doctorData.personality = null
            this.doctorData.birthdate = null
            this.doctorData.weekend = null
            this.doctorData.personalNote = null 
            this.doctorData.doctorHobbies = null
            this.doctorData.nearbyPharmaciesOne = null
            this.doctorData.nearbyPharmaciesTwo = null
            this.doctorData.visitNotes = null
            this.doctorData.worksInCenters = null 
            this.doctorData.pmWorkHours = null
            this.doctorData.amWorkHours = null
        },

        editDoctor(item){
            this.doctorId = item.id;
            this.resetDoctorInputs();
            var doctor = item;

            this.doctorData.name = doctor?.name;
            this.doctorData.specialtyId = doctor?.specialtyIds;
            if (this.firstLevel) {
                this.doctorData.loyality = doctor?.loyality
                this.doctorData.relegion = doctor?.relegion
                this.doctorData.personality = doctor?.personality
                this.doctorData.birthdate = doctor?.birthdate
                this.doctorData.weekend = doctor?.weekend
                this.doctorData.personalNote = doctor?.personalNote 
                this.doctorData.doctorHobbies = doctor?.doctorHobbies
                this.doctorData.nearbyPharmaciesOne = doctor?.nearbyPharmacies
                this.doctorData.nearbyPharmaciesTwo = doctor?.nearbyPharmacies
                this.doctorData.visitNotes = doctor?.visitNotes
                this.doctorData.worksInCenters = doctor?.worksInCenters
                this.doctorData.pmWorkHours = doctor?.pmWorkHours
                this.doctorData.amWorkHours = doctor?.amWorkHours
            }
        
            doctor.contactInfos.forEach((contactInfo) => {
                this.doctorData.contactInfo.push(
                    {
                        id: ++this.numContact,
                        contactInfoType: contactInfo.contactInfoType,
                        value: contactInfo.value,
                    }
                );
            });
            
            doctor.entityAddresses.forEach((address) => {
                this.doctorData.addresses.push(
                    {
                        id: ++this.numAddresses,
                        cityId: this.getRegionAllById(address.regionId)?.cityId,
                        regionId: address.regionId,
                        workloadId : address.workloadId,
                        address: address.address,
                        visitTime: address.visitTime,
                        bestTimeToVisit: address.bestTimeToVisit
                    }
                );
            });

            this.doctorDialog = true;
        },

        // directory dialog functions
        resetDirectoryInputs(){
            if (this.$refs.directoryForm) this.$refs.directoryForm.resetValidation();
            this.directoryId = null;

            this.directoryData.name = null;
            this.directoryData.entityType = null;
            this.directoryData.specialtyId = [];
            this.directoryData.doctorNames = null
            this.directoryData.pharmacistInfo = null
            this.directoryData.pharmacyInfo = null
            this.directoryData.cityId = null;
            this.directoryData.contactInfo = [
                { contactInfoType: contactInfoType.phone, value: null },
                { contactInfoType: contactInfoType.landline, value: null }
            ];
            this.directoryData.addresses = [{ address: null, regionId: null, workloadId: null, bestTimeToVisit: null }];
        },

        editDirectory(item) {
            this.resetDirectoryInputs();
            this.directoryData.entityType = this.entityTypeForCurrentTab;
            this.directoryDialog = true;

            this.directoryId = item.id;

            const directory = item
            this.directoryData.name = directory.name;
            this.directoryData.entityType = directory.entityType;

            this.directoryData.specialtyId = directory.specialtyIds
            this.directoryData.pharmacistInfo = this.firstLevel ? directory.pharmacistInfo : null
            this.directoryData.pharmacyInfo = this.firstLevel ? directory.pharmacyInfo : null

            // get contactInfos
            if (directory.contactInfos.length){
                /* phone */
                this.directoryData.contactInfo[0].contactInfoType = 0;
                this.directoryData.contactInfo[0].value = directory.contactInfos[0].value;

                /* landline */
                this.directoryData.contactInfo[1].contactInfoType = 1;
                this.directoryData.contactInfo[1].value = directory.contactInfos[1].value;
            }

            // addresses
            this.directoryData.cityId = this.getRegionAllById(directory.entityAddresses[0].regionId).cityId;
            this.fetchRegionsDueCity(this.directoryData.cityId);
            this.directoryData.addresses[0].address = directory.entityAddresses[0].address;
            this.directoryData.addresses[0].regionId = directory.entityAddresses[0].regionId;
            this.directoryData.addresses[0].workloadId = directory.entityAddresses[0].workloadId;
            this.directoryData.addresses[0].bestTimeToVisit = directory.entityAddresses[0].bestTimeToVisit
        },

        // submit
        submit(){
            const isCurrentDoctor = this.tab === 0;
            const isValid = isCurrentDoctor ? this.$refs.doctorForm.validate() : this.$refs.directoryForm.validate();
            if (isValid){
                this.submitLoading = true;
                const actionName =
                    isCurrentDoctor
                        ? (!this.doctorId ? 'create' : 'update')
                        : (!this.directoryId ? 'create' : 'update');
                const id = isCurrentDoctor ? this.doctorId : this.directoryId;
                const data = 
                    isCurrentDoctor
                        ? {
                            name: this.doctorData.name,
                            entityType : entityType.doctor,
                            specialtyId: this.doctorData.specialtyId,
                            contactInfo: this.doctorData.contactInfo.filter(c => c.value !== '').length ? this.doctorData.contactInfo.map(({ id, ...data }) => data).filter(c => c.value !== '') : [],
                            addresses: this.doctorData.addresses.map(({ id, ...data }) => data),
                            // bestTimeToVisit: '2023-11-25T08:22:08.243Z',
                            openingTime: "2023-11-25T08:22:08.243Z",
                            closingTime: "2023-11-25T08:22:08.243Z",
                            residentDoctorNames: "string",
                            loyality: this.firstLevel ? this.doctorData.loyality : null,
                            relegion: this.firstLevel ? this.doctorData.religion : null,
                            personality: this.firstLevel ? this.doctorData.personality : null,
                            birthdate: this.firstLevel ? this.doctorData.birthdate : null,
                            weekend: this.firstLevel ? this.doctorData.weekend : null,
                            personalNote: this.firstLevel ? this.doctorData.personalNote : null,
                            doctorHobbies: this.firstLevel ? this.doctorData.doctorHobbies : null,
                            nearbyPharmacies: this.firstLevel ? this.doctorData.nearbyPharmaciesOne + this.doctorData.nearbyPharmaciesTwo : null,
                            visitNotes: this.firstLevel ? this.doctorData.visitNotes : null,
                            worksInCenters: this.firstLevel ? this.doctorData.worksInCenters : null,
                            pmWorkHours: this.firstLevel ? this.doctorData.pmWorkHours : null,
                            amWorkHours: this.firstLevel ? this.doctorData.amWorkHours : null,
                        }
                        : {
                            name: this.directoryData.name,
                            entityType : this.directoryData.entityType,
                            specialtyId: this.directoryData.specialtyId,
                            // contactInfo: this.directoryData.contactInfo,
                            contactInfo: this.directoryData.contactInfo.map(({ id, ...data }) => data),
                            addresses: this.directoryData.addresses[0].workloadId !== null ? this.directoryData.addresses : [
                                {
                                    address: this.directoryData.addresses[0].address,
                                    regionId: this.directoryData.addresses[0].regionId,
                                    bestTimeToVisit: this.directoryData.addresses[0].bestTimeToVisit
                                }
                            ],
                            // bestTimeToVisit: this.directoryData.bestTimeToVisit,
                            openingTime: "2023-11-25T08:22:08.243Z",
                            closingTime: "2023-11-25T08:22:08.243Z",
                            residentDoctorNames: this.directoryData.doctorNames ? this.directoryData.doctorNames : '',
                            pharmacistInfo: this.firstLevel ? this.directoryData.pharmacistInfo : null,
                            pharmacyInfo: this.firstLevel ? this.directoryData.pharmacyInfo : null
                        };

                this.$store.dispatch(`entities/${actionName}`, { id, data }).then(() => {
                    const message = this.currentTabLabel + (actionName === 'create' ? ' is added' : ' is edited');
                        
                    this.$eventBus.$emit('show-snackbar', message, 'info');

                    this.needToResetItems = true;
                    this.filter();

                    this.$refs.tableAddress.resetEdit()
                    this.$refs.doctorContactsTable.resetEdit()

                    this.doctorId = null;
                    this.directoryId = null;
                    
                    this.doctorDialog = false;
                    this.directoryDialog = false;
                }).finally(() => {
                    this.submitLoading = false;
                    this.isAdd = false;
                    this.directoryDialog = false;
                })
            }
        },

        toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.setRegions()
                }
            })
        },

        addCenterContactRow() {
            if (this.$refs.tableContactCenter.validate() && !this.isContactTableCenterInputsEmpty) {
                this.directoryData.contactInfo.push({
                    id: ++this.numContact,
                    contactInfoType: this.directoryTableInputs.contactInfo.contactType,
                    value: this.directoryTableInputs.contactInfo.contactValue
                });
                this.$refs.tableContactCenter.resetInputs();
                this.$refs.tableContactCenter.resetInputsFocus();
            }
        },

        getCitiesFilter() {
            if (this.sectorId !== null) {
                this.loading = true;
                this.$store.dispatch('sectors/fetchCitiesInSector', { id: this.sectorId }).finally(() => {
                    this.loading = false
                })
            }
        },

        fetchAllCities() {
            this.sectorId = null
            this.loading = true
            this.$store.dispatch('cities/fetchCityMine').finally(() => {
                this.loading = false
            })
        },
        ImportExcel() {
            this.excelLoading = true
            this.$store.dispatch('exporter/fetchEntities', { 
                type: this.entityTypeForCurrentTab,
                name: this.name,
                workloadIds: this.workload,
                specialtyId: this.specialtyId,
                regionIds: this.regionIds.length ? this.regionIds : [],
                nonDeleted: true,
                OrderByWorkLoad: this.sort === 1,
                OrderByName: this.sort === 0,
                WithVisitsCount: false
            }).finally(() => {
                this.excelLoading = false
            })
        },

        merge() {
            this.submitLoading = true
            this.$store.dispatch('entities/merge', { oldEntityId: this.entryItem.id, newEntityId: this.newEntity }).then(() => {
                this.$store.dispatch('entities/delete', { id: this.entryItem.id }).then(() => {
                    this.needToResetItems = true
                    this.filter()
                })
            }).finally(() => {
                this.submitLoading = false
                this.mergeDialog = false
                this.entryItem = null
            })
        },

        submitToggle() {
            this.submitLoading = true
            this.$store.dispatch('entities/updateActive', { id: this.entityId, active: !this.entityItem.active })
            .then(() => {
                this.needToResetItems = true
                this.filter()
            }).finally(() => {
                this.submitLoading = false
                this.dialogToggle = false
            })
        },

        fetchAccountByTab(tab) {
            let entityType = null
            this.accountsCustomer = []
            if (tab === 0) {
                entityType = 1
            } else if (tab === 1) {
                entityType = 2
            } else {
                entityType = 0
            }
            this.$store.dispatch('accounts/fetchAllBrief', { type: 1, entityType: entityType }).then((data) => {
                this.accountsCustomer = data
            })
        },

        getEntityByTab () {
            this.needToResetItems = true;
            this.clearFilter(); 
            Promise.all([
                this.fetchAccountByTab(this.tab),
                this.filter()
            ])
        }
    },

    mounted(){
        this.loading = true;

        this.needToResetItems = true;
        
        Promise.all([
            this.$store.dispatch('workload/fetchAll'),
            this.filter(),
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                // this.$store.dispatch('sectors/fetchSectorCities', { cities: data })
            }) : null,
            // !this.sectorsMine.length ? this.$store.dispatch('sectors/getSectorsMine') : null,
            !this.regionsAll.length ? this.$store.dispatch('regions/fetchAllRegions', {
                cities: this.cities
            }) : null,
            this.fetchAccountByTab(0)
        ])
        .then(() => {
        }).finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Customers',
        titleTemplate: '%s | Octopharma'
    }
}
</script>