<template>
    <div>
        <!-- tabs -->
        <v-tabs v-model="tab" show-arrows height="35">
            <v-tab
                class="text-capitalize "
                :to="{ 
                    name: 'samples-statement'
                }"
            >
               Materials statement    
            </v-tab>

            <v-tab
                class="text-capitalize "
                :to="{ 
                    name: 'warehouses-statistics'
                }"
            >
                Warehouses Statistics
            </v-tab>

            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-divider v-if="!$route.query.preview" class="mb-2"/>

         <!-- child page -->
        <loader v-if="loading"/>
        <router-view v-else/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab: 0,
            loading: false
        }
    }
}
</script>
