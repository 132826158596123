import * as types from '../mutation-types'
import { downloadBlob } from '@/helpers/functions';
export default {
    namespaced: true,

    state: {},

    actions: {
        fetchVisitsExport({ commit }, { FromDate, ToDate, EntityType, RegionIds, EmployeeId, EntityName, EntityId, SpecialtyId, Workload, PerPage, Page, CoreMessage }) {
            return window.axios.get('/exporter/visitsExport', { params: { FromDate, ToDate, EntityType, RegionIds, EmployeeId, EntityName, EntityId, SpecialtyId, Workload, PerPage, Page, CoreMessage }, responseType: 'blob' }).then(({ data }) => {
                downloadBlob(data, `Visits ${new Date().toISOString().slice(0, 10)}.xlsx`);
            })
        },

        fetchStatementExport({ commit }, { MaterialId, Date, FromDate, ToDate, AccountId, Type, WithVisitTransaction, SelectInAmount, SelectOutAmount, SortByCreatedAt, PerPage, Page }) {
            return window.axios.get('/exporter/StatementExport', { params: { MaterialId, Date, FromDate, ToDate, AccountId, Type, WithVisitTransaction, SelectInAmount, SelectOutAmount, SortByCreatedAt, PerPage, Page }, responseType: 'blob' }).then(({ data }) => { downloadBlob(data, `Statement ${new Date().toISOString().slice(0, 10)}.xlsx`); })
        },

        fetchTransactionSummaryExport({ commit }, { id }) {
            return window.axios.get('/exporter/TransactionSummaryExport', { params: { id }, responseType: 'blob' }).then(({ data }) => {
                downloadBlob(data, `Transaction Summary ${new Date().toISOString().slice(0, 10)}.xlsx`);
            })
        },

        fetchPlanExport({ commit }, { employeeId, fromDate }) {
            return window.axios.get('/exporter/planExport', { params: { employeeId, fromDate }, responseType: 'blob' }).then(({ data }) => {
                downloadBlob(data, `Plan ${new Date().toISOString().slice(0, 10)}.xlsx`);
            })
        },

        fetchAccountStatisticsExport({ commit }, { AccountId }) {
            return window.axios.get('/exporter/AccountStatisticsExport', { params: { AccountId }, responseType: 'blob' }).then(({ data }) => {
                downloadBlob(data, `Account Statistics ${new Date().toISOString().slice(0, 10)}.xlsx`);
            })
        },

        fetchMaterialStatisticsExport({ commit }, { CityId, MaterialId }) {
            return window.axios.get('exporter/MaterialStatisticsExport', { 
                params: { CityId, MaterialId }, responseType: 'blob' 
            }).then(({ data }) => {
                downloadBlob(data, `Material Statistics ${new Date().toISOString().slice(0, 10)}.xlsx`);
            })
        },

        fetchEntities({ commit }, { type, name, workloadIds, specialtyId, regionIds, page, perPage, nonDeleted, OrderByWorkLoad, OrderByName, WithVisitsCount, includeAddresses, includeContactInfo, employeeId }) {
             var urlSearchParams = new URLSearchParams();
            var params = { type, name, specialtyId, page, perPage, nonDeleted, OrderByWorkLoad, OrderByName, WithVisitsCount, employeeId }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id)
            })
            workloadIds.forEach(id => {
                urlSearchParams.append('workloadIds', id)
            })
            urlSearchParams.append('includeContactInfo', true);
            urlSearchParams.append('includeAddresses', true);
            return window.axios.get('exporter/Entities', { 
                params: urlSearchParams,
                responseType: 'blob' 
            }).then(({ data }) => {
                downloadBlob(data, `Entities ${new Date().toISOString().slice(0, 10)}.xlsx`);
            })
        },

        fetchStaff({ commit }, { name, id, regionIds, nonDeleted, teamIds }) {
            var urlSearchParams = new URLSearchParams();
           var params = { name }

            Object.keys(params).forEach(param => {
               if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(regionId => {
               urlSearchParams.append('regionIds', regionId)
            })

            teamIds.forEach(teamId => {
                urlSearchParams.append('teamIds', teamId)
            })
       
           urlSearchParams.append('nonDeleted', true);
           return window.axios.get(`exporter/Role/${id}/employees`, { 
               params: urlSearchParams,
               responseType: 'blob' 
           }).then(({ data }) => {
               downloadBlob(data, `Entities ${new Date().toISOString().slice(0, 10)}.xlsx`);
           })
       }
    },

    mutations: {}
}