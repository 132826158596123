<template>
	<div id="home">
		<v-row>
			<v-col cols="auto" md="auto" xl="2" class="pb-0 pe-1">
				<div
					style="background-color: #0b5b6f0f; width: 100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							{{visitsAchievements.visitPLanned}} / 
							<span class="success--text headline font-weight-bold">{{visitsAchievements.visitDone}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 caption ">
							Planned visits / Visits done
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-map-marker </v-icon>
				</div>
			</v-col>
			<!-- 2 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0 px-1">
				<div
					style="background-color: #0b5b6f0f; width: 100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-center align-center"
				>
					<div>
					<v-row class="text-start d-flex flex-column justify-center align-start ">
						<v-col cols="10" >
							<span class="font-weight-bold headline pb-0 mb-0 pb-0 " style="" > 
								{{visitCount.monthVisit}} /
								<span class="success--text headline font-weight-bold">
									{{visitCount.yesterdayVisit}}
									<!-- 233 -->
								</span>  
							</span>
						</v-col>
						<!-- <br /> -->
					</v-row>
					<p class="mb-0 caption ">
						Month Visits / Yesterday visits
					</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-map-marker </v-icon>
				</div>
			</v-col>
			
			<!-- 3 -->
			<v-col cols="auto" md="1" xl="1" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width: 100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name:'day-off-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<div class="d-flex justify-space-between font-weight-bold pb-0 mb-0 pb-0 headline" style="width: 100%">  
							<span class="success--text headline font-weight-bold"> {{leaveRequests}} </span>  
							<!-- <v-spacer /> -->
							<v-icon color="secondary" style="align-self: center"> mdi-calendar-alert  </v-icon>
						</div>
						<!-- <br /> -->
						<p class="mb-0 caption ">
							leave Requests
						</p>
					</div>
					
				</v-btn>
			</v-col>
			<!-- 4 -->
			<v-col cols="auto" md="auto" xl="1" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width:100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name: 'day-off-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{administrativeRequests}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 caption ">
							Administrative Day
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-map-marker </v-icon>
				</v-btn>
			</v-col>
			<!-- 5 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width: 100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name:'modification-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{entityModifications}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 " style="font-size: 11.4px">
							Modification requests
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-circle-edit-outline  </v-icon>
				</v-btn>
			</v-col>
			<!-- 6 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width:100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name:'modification-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{entityCreations}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 " style="font-size: 11.4px">
							Customer addition requests
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-shape-circle-plus  </v-icon>
				</v-btn>
			</v-col>
			<!-- 7 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0">
				<div
					style="background-color: #0b5b6f0f; width:100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{entityDeletions}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 " style="font-size: 11.4px">
							Customer deletion requests
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-delete-circle  </v-icon>
				</div>
			</v-col>
		</v-row>

		<!-- charts -->
		<v-row>
			<!-- new staff -->
			<v-col lg="6" md="12" cols="12" class="pt-2">
				<v-row>
					<v-col v-if="0" cols="12" class="px-0 pb-0">
						<!-- staff -->
						<v-card 
							style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
							class="mt-2 mx-2 rounded" 
							height="100" 
							width=""
						>
							<v-card-text>
								<v-row class="px-2">
									<!-- staff -->
									<v-icon color="secondary" small class="mx-1"> mdi-hand-wave  </v-icon>
									<p class="primary--text font-weight-bold mb-0" > Welcome new members </p>
									
									<v-col class="d-flex justify-space-between " cols="12">
										<!-- Who joined us recently -->
										<div v-for="(member, i) in staff.slice(0, 3)" :key="i" class="d-flex  text-start">
											<v-icon color="secondary"> mdi-account-multiple-plus </v-icon>
											<div>
												<p class="mb-0 mx-2 font-weight-bold"> {{member.name}} </p>
												<p class="mb-0 mx-2 text-start"> {{member.roleName}} </p>
											</div>
										</div>

									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					
					<!-- visits -->
					<v-col cols="12" class="pa-0 py-0">
						<v-card 
							style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
							class="mt-4 mx-2 rounded" 
							height="auto" 
							width="98%"
						>
							<v-card-text class="">
								<div class="d-flex">
									<v-icon class="mx-1" color="secondary"> mdi-select-marker  </v-icon>
									<p class="font-weight-bold primary--text mb-0"> Number of visits for the last week according to classification </p>
								</div>
								<v-row class="py-2 px-5">
									<v-col class="pb-0 pe-1" cols="4">
										<v-autocomplete 
											v-model="cityId"
											label="City"
											dense
											:items="cities"
											item-text="name"
											item-value="id"
											hide-details
											outlined
											@change="getWorkloadByFilter()"
											:loading="submitLoading || loading"
											:disabled="loading || submitLoading || loadingClassificationStat"
										/>
									</v-col>
									<v-col v-if="0" class="pb-0 px-1" cols="4">
										<bee-date-picker
											v-model="fromDate" 
											input-label="From Date"
											elevation="2"
											menu-picker
											dense
											hide-details
											:input-props="{
												outlined: true,
												dense: true,
												loading: submitLoading || loading
											}"
											min="1940-01-01"
											:disabled="loading || Boolean($route.query.preview) || submitLoading"
										/>
									</v-col>

									<v-col @click="getClass()"  class="pb-0 px-1" cols="4">
										<bee-date-picker 
											v-if="0"
											v-model="toDate"
											input-label="To Date"
											elevation="2"
											menu-picker
											dense
											hide-details
											:input-props="{
												outlined: true,
												dense: true,
												loading: submitLoading || loading
											}"
											min="1940-01-01"
											:disabled="loading || Boolean($route.query.preview) || submitLoading"
										/>
										<v-btn 
											@click="getWorkloadByFilter()" 
											color="success" 
											small 
											class="text-none" 
											:disabled="submitLoadingWorkLoad || loading"
											:loading="submitLoadingWorkLoad"
										> Search </v-btn>
									</v-col>
								</v-row>
								<apexchart
									type="bar"
									height="320"
									width="97%"
									:loading="loadingClassificationStat"
									:series="series"
									:options="options"
								></apexchart>
								<v-progress-linear
									v-if="loadingClassificationStat"
									color="primary"
									indeterminate
									reverse
								></v-progress-linear>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
			<v-col lg="6" md="12" cols="12" class="py-0 ">
				<v-card
					style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
					class="mt-4 mx-2 rounded" 
					height="auto" 
					width="98%"
				>
					<v-card-text>
						<!-- materials -->
						<div class="d-flex">
							<v-icon color="secondary"> mdi-ballot   </v-icon>
							<p class="font-weight-bold primary--text px-1 mb-1"> Outgoing and remaining materials</p>
						</div>
						<v-row v-if="0" class="pt-1 px-5">	
							<v-col class="pb-0 px-1" cols="4">
								<bee-date-picker
									v-model="fromDateMaterialsChart" 
									input-label="From Date"
									elevation="2"
									menu-picker
									dense
									hide-details
									:input-props="{
										outlined: true,
										dense: true,
										loading: submitLoading || loading
									}"
									min="1940-01-01"
									:disabled="loading || Boolean($route.query.preview)"
								/>
							</v-col>

							<v-col class="pb-0 px-1" cols="3">
								<bee-date-picker 
									v-model="toDateMaterialsChart"
									input-label="To Date"
									elevation="2"
									menu-picker
									dense
									hide-details
									:input-props="{
										outlined: true,
										dense: true,
										loading: submitLoading || loading
									}"
									min="1940-01-01"
									:disabled="loading || Boolean($route.query.preview)"
								/>
							</v-col>

							<v-col>
								<v-btn color="success" small class="text-none">
									Search
								</v-btn>
							</v-col>
						</v-row>
					
						<apexchart
							type="bar"
							height="auto"
							width="97%"
							:loading="loadingWidelyMaterial"
							:series="seriesMaterials"
							:options="optionsMaterials"
						></apexchart>
						<v-progress-linear
							v-if="loadingWidelyMaterial"
							color="primary"
							indeterminate
							reverse
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-col>
			<!-- new -->
			<v-col lg="12" md="12" cols="12" class="py-2">
				<!-- materials -->
				<v-card
					style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
					class="mt-4 mx-2 rounded" 
					height="auto" 
					width=""
				>
					<v-card-text class="pa-0">
						<v-tabs v-model="tabTopAndLessMaterials">
							<v-tab>
								The ten most widely used materials
							</v-tab>
							<v-tab>
								The ten stagnant materials
							</v-tab>
							<v-tabs-items  v-model="tabTopAndLessMaterials"> 
								<v-tab-item>
									<apexchart 
										type="bar" 
										height="250"
										width="95%"
										:loading="loadingWidelyMaterial"
										:series="seriesTop3Materials"
										:options="chartOptionsTop3Materials"
									></apexchart>
									<v-progress-linear
										v-if="loadingWidelyMaterial"
										color="primary"
										indeterminate
										reverse
									></v-progress-linear>
									<div v-if="0" class="d-flex justify-center py-0 my-0">
										<v-icon small color="secondary" class="mx-2"> mdi-arrow-up-circle  </v-icon>
										<h4 class="text-center  py-0 my-0"> Top 3 commonly used materials </h4>
									</div>
								</v-tab-item>

								<v-tab-item>
									<apexchart 
										type="bar" 
										height="250"
										width="100%"
										:loading="loadingWidelyMaterial"
										:series="seriesLowAmountMaterials"
										:options="lowAmountMaterialsChartOptions"
									></apexchart>
									<v-progress-linear
										v-if="loadingWidelyMaterial"
										color="primary"
										indeterminate
										reverse
									></v-progress-linear>
									<div v-if="0" class="d-flex justify-center">
										<v-icon small color="secondary" class="mx-2"> mdi-arrow-down-circle  </v-icon>
										<h4 class="text-center">Least 3 commonly used materials</h4>
									</div>
								</v-tab-item>
							</v-tabs-items>
						</v-tabs>
					</v-card-text>
				</v-card>
			</v-col>
	
			<v-col v-if="!firstLevel" lg="12" md="12" cols="12" class="">
				<v-card
					style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
					class="mt-4 mx-2 rounded" 
					height="250" 
					width="100%"
				>
					<v-card-text>
						<div class="d-flex">
							<v-icon color="secondary" class="mx-1"> mdi-semantic-web  </v-icon>
							<p class="mb-0 primary--text font-weight-bold"> Number of visits for the last week depending on the number of customers</p>
						</div>
							<v-row >
								<v-col cols="3">
									<v-autocomplete
										v-model="cityIdVisitsRate"
										:items="cities"
										item-text="name"
										item-value="id"
										label="City"
										outlined
										hide-details
										dense
										:loading="loading"
										:disabled="loadingNumberVisit || loading"
									/>
								</v-col>

								<v-col v-if="0" cols="3">
									<bee-date-picker 
										v-model="fromDateVisitsToEntity" 
										input-label="From Date"
										elevation="2"
										menu-picker
										dense
										hide-details
										:input-props="{
											outlined: true,
											dense: true,
											loading: submitLoading || loadingNumberVisit
										}"
										min="1940-01-01"
										:disabled="loading || Boolean($route.query.preview) || submitLoading"
									/>
								</v-col>

								<v-col v-if="0" cols="3">
									<bee-date-picker 
										v-model="toDateVisitsToEntity" 
										input-label="To Date"
										elevation="2"
										menu-picker
										dense
										hide-details
										:input-props="{
											outlined: true,
											dense: true,
											loading: submitLoading || loading
										}"
										min="1940-01-01"
										:disabled="loading || Boolean($route.query.preview) || submitLoading"
									/>
								</v-col>

								<v-col>
									<v-btn 
										@click="getVisitsToEntity()" 
										color="success" 
										small 
										class="text-none"
										:disabled="loading || submitLoading || loadingNumberVisit"
										:loading="submitLoading"
									>
										Search
									</v-btn>
								</v-col>
							</v-row>
							<apexchart
								type="bar"
								height="250"
								width="95%"
								:series="seriesRateVisit"
								:options="chartOptionsRateVisit"
							></apexchart>
							<v-progress-linear
								v-if="loadingNumberVisit"
								color="primary"
								indeterminate
								reverse
							></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { firstLevel } from '@/configs/global.config'
 
	export default {
		name: 'Home',

		data(){
			return {
				loadingClassificationStat: false,
				loadingMaterial: false,
				loadingWidelyMaterial: false,
				loadingNumberVisit: false,
				fromDateVisitsToEntity:  moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
				toDateVisitsToEntity: moment().format('YYYY-MM-DD'),
				tab: 0,
				tabTopAndLessMaterials: 0,
				fromDateMaterialsChart: moment().format('YYYY-MM-DD'),
				toDateMaterialsChart: moment(this.moment().endOf('month')).format('YYYY-MM-DD'),
				visitsNumber: null,
				totalVisitsNumber: null,
				leaveRequests: 0,
				administrativeRequests: 0,
				entityModifications: 0,
				entityCreations: 0,
				entityDeletions: 0,
				cityId: null,
				fromDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
				toDate: moment().format('YYYY-MM-DD'),
				loading: false,
				submitLoading: false,
				cityIdVisitsRate: null,
				fromDateVisitsRate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
				toDateVisitsRate: moment().format('YYYY-MM-DD'),
				submitLoadingWorkLoad: false,
				firstLevel
			}
		},
		
		computed:{
			...mapState({
				medicalreps: state => state.medicalreps.medicalreps,
				employeeId: state => state.auth.userData.id,
				classification: state => state.workload.classification,
				workloadVisits: state => state.statisticsHome.workloadVisits,
				staff: state => state.statisticsHome.staff.lastEmployees,
				visitsAchievements: state => state.statisticsHome.visitsAchievements,
				visitCount: state => state.statisticsHome.visitCount,
				staffRequests: state => state.statisticsHome.staffRequests,
				materialsStat: state => state.statisticsHome.materialsStat,
				materials: state => state.materials.materials,
				teams: state => state.statisticsHome.teams,
				allTeams: state => state.teams.teams,
				cities: state => state.cities.cities,
				entitiesVisits: state => state.statisticsHome.entitiesVisits,
				regionsAll: state => state.regions.regionsAll
			}),

			...mapGetters({
				getClassificationById: 'workload/getClassificationById',
				getMedicalRepById: 'medicalreps/getMedicalRepById',
				getMaterialById: 'materials/getMaterialById',
				getTeamById: 'teams/getTeamById',
				getRegionAllById: 'regions/getRegionAllById'
			}),

			regionsName() {
				// console.log (this.entitiesVisits.map(c => this.getRegionAllById(c.visits.regionId)?.name))/
				return this.entitiesVisits.slice(0, 20).map(c => this.getRegionAllById(c.visits.regionId)?.name)
			},

			// classification
			series() {
				return [
					{
						name: 'Visit cont',
						data: this.workloadVisits.map(c => c.visitCount)
					},
					{
						name: 'Entity count',
						data: this.workloadVisits.map(c => c.entityCount)
					},

				]
			},

			options() { 
				return {
					chart: {
						id: 'vuechart-example'
					},
					xaxis: {
						categories: this.workloadVisits.map(c => this.getClassificationById(c.workloadId).name)
					}
				}
			},
			// labels
			labels () {
				return	this.classification.map(c => c.name)
			},

			// Remaining
			seriesMaterials() {
				return [
					{
						name: 'Deliver Amount',
						data: this.materialsStat?.materialState.slice(0, 10).map(c => c.amountDeliverd)
						
					},
					{
						name: 'Remaining',
						data: this.materialsStat?.materialState.slice(0, 10).map(c => c.amountInWarehouse)
					}
				]
			},
			optionsMaterials() {
				// return {
				// 	chart: {
				// 		id: 'vuechart-example'
				// 	},
				// 	xaxis: {
				// 		categories: this.materialsStat?.materialState.slice(0, 20).map(c => this.getMaterialById(c.materialId)?.name)
				// 	},
                //     dataLabels: {
				// 		enabled: true,
				// 		offsetY: -20,
				// 		style: {
				// 			fontSize: '12px',
				// 			colors: ["#304758"]
				// 		}
				// 	},
				// 	// colors: ['#1ab7ea', '#FF6103', '#39539E'],
				// }
                return {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: this.materialsStat?.materialState.slice(0, 10).map(c => this.getMaterialById(c.materialId)?.name),
                    },
                    yaxis: {
                        title: {
                            
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            	return val 
							}
                    	}
                	}
            	}
        },
			// labels() {
			// 	return	this.classification.map(c => c.name)
			// },

			// quantity and team
			quantityInTeam() {
				return [
					{
						name: 'Quantity received',
						data: this.teams.map(c => c.inAmountSum)
						
					},
					{
						name: 'Quantity delivered',
						data: this.teams.map(c => c.outAmountSum)
					}
				]
			},

			optionsMaterialsTeam() {
				return {
					chart: {
						id: 'vuechart-example'
					},
					xaxis: {
						categories: this.teams.map(c => this.getTeamById(c.teamId)?.name)
					}
      			}
			},

			// circle
			seriesTop3Materials() {
				return [{
					name: '',
					data: this.materialsStat?.topMaterial.slice(0, 10).map(c => c.materialAmount)
				}]
			},

			chartOptionsTop3Materials () {
				return {
					chart: {
						height: 350,
						type: 'bar',
					},
					plotOptions: {
						bar: {
							borderRadius: 0,
                            width: '300px',
							dataLabels: {
								position: 'top', // top, center, bottom
							},
						}
					},
					dataLabels: {
						enabled: true,
						offsetY: -20,
						style: {
							fontSize: '12px',
							colors: ["#304758"]
						}
					},
            
					xaxis: {
						categories: this.materialsStat.topMaterial.slice(0, 10).map(c => this.getMaterialById(c.materialId)?.name),
						// position: 'top',
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false
						},
						crosshairs: {
							fill: {
							type: 'gradient',
							gradient: {
								colorFrom: '#D8E3F0',
								colorTo: '#BED1E6',
								stops: [0, 100],
								opacityFrom: 0.4,
								opacityTo: 0.5,
							}
							}
						},
						tooltip: {
							enabled: true,
						}
					},
					yaxis: {
						axisBorder: {
							show: true
						},
						axisTicks: {
							show: false,
						},
						labels: {
							show: true,
							formatter: function (val) {
								return val
							}
						}
						
					},
					// title: {
					// 	text: 'Top 3 commonly used materials',
					// 	floating: true,
					// 	offsetY: 330,
					// 	align: 'bottom',
					// 	style: {
					// 		color: '#444'
					// 	}
					// }
				}
			},

			seriesLowAmountMaterials () {
				// return this.materialsStat?.lessMaterial.map(c => c.materialAmount)
				return [{
					name: 'less 3',
					data: this.materialsStat?.lessMaterial.slice(0, 10).map(c => c.materialAmount)
				}]
			},

			lowAmountMaterialsChartOptions () {
				// return {
				// 	chart: {
				// 		id: 'vuechart-example'
				// 	},
				// 	xaxis: {
				// 		categories: this.materialsStat?.lessMaterial.map(c => this.getMaterialById(c.materialId)?.name)
				// 	}
      			// }
				return {
					chart: {
						height: 350,
						type: 'bar',
					},
					plotOptions: {
						bar: {
							borderRadius: 0,
                            width: '300px',
							dataLabels: {
								position: 'top', // top, center, bottom
							},
						}
					},
					dataLabels: {
						enabled: true,
						offsetY: -20,
						style: {
							fontSize: '12px',
							colors: ["#304758"]
						}
					},
            
					xaxis: {
						categories: this.materialsStat?.lessMaterial.map(c => this.getMaterialById(c.materialId)?.name),
						// position: 'top',
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false
						},
						crosshairs: {
							fill: {
							type: 'gradient',
							gradient: {
								colorFrom: '#D8E3F0',
								colorTo: '#BED1E6',
								stops: [0, 100],
								opacityFrom: 0.4,
								opacityTo: 0.5,
							}
							}
						},
						tooltip: {
							enabled: true,
						}
					},
					yaxis: {
						axisBorder: {
							show: true
						},
						axisTicks: {
							show: false,
						},
						labels: {
							show: true,
							formatter: function (val) {
								return val
							}
						}
						
					},
					title: {
						text: 'Monthly Inflation in Argentina, 2002',
						floating: true,
						offsetY: 330,
						align: 'center',
						style: {
							color: '#444'
						}
					}
				}
			},

			// new (medRep-ent-visits) 
        	seriesRateVisit() {
                return [
                    {
                        name: 'Visits number',
						data: this.entitiesVisits.slice(0, 20).map(c => c.visits.count)
                    }, 
                    {
                        name: 'Entities number',
						data: this.entitiesVisits.slice(0, 20).map(c => c.entities.count)
                    }, 
                ]
			}, 
          	chartOptionsRateVisit () {
				return {
					chart: {
						type: 'bar',
						height: 450,
						stacked: true,
					},
					stroke: {
						width: 1,
						colors: ['#fff']
					},
					xaxis: {
						categories: this.regionsName,
						labels: {
							formatter: function (val) {
							return val
							}
						}
					},
					yaxis: {
						title: {
							text: undefined
						},
					},
					tooltip: {
					y: {
						formatter: function (val) {
						return val
						}
					}
					},
					fill: {
					opacity: 1
					},
					legend: {
					position: 'top',
					horizontalAlign: 'left',
					offsetX: 40
					}
          		}
			}
        },

		methods:{
			moment,
			
			getWorkloadByFilter() {
				this.submitLoadingWorkLoad = true
				this.$store.dispatch('statisticsHome/fetchWorkloadVisits', { 
					fromDate: this.fromDate, 
					toDate: this.toDate,
					cityId: this.cityId
				}).finally(() => {
					this.submitLoadingWorkLoad = false
				})
			},

			getVisitsToEntity () {
				this.submitLoading = true
				this.$store.dispatch('statisticsHome/fetchEntitiesVisits', {
					cityId: this.cityIdVisitsRate,
					fromDate: this.fromDateVisitsRate,
					toDate: this.toDateVisitsRate,
				}).finally(() => {
					this.submitLoading = false
				})
			}
		},

		created() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('cities/fetchCityMine').then((data) => {
					this.cityIdVisitsRate = data[0].id
					this.cityId = data[0].id
				}),
				// this.$store.dispatch('cities/fetchCityMine').then((data) => {
				// this.cityIdVisitsRate = data[0].id
                // }),
                !this.classification.length ? this.$store.dispatch('workload/fetchAll') : null,
                !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
                !this.materials.length ? this.$store.dispatch('materials/fetchAll', {}) : null,
			]).then(() => {
				this.loading = false
				this.loadingClassificationStat = true
				this.loadingMaterial = true
				this.loadingWidelyMaterial = true
				this.loadingNumberVisit = true
				this.$store.dispatch('regions/fetchAllRegions', { cities: this.cities })
				window.axios.get('/home').then(response => {
					this.leaveRequests = response.data.leaveRequests;
					this.administrativeRequests = response.data.administrativeRequests;
					this.entityModifications = response.data.entityModifications;
					this.entityCreations = response.data.entityCreations;
					this.entityDeletions = response.data.entityDeletions;
					this.visitsNumber = response.data.visitsYesterday;
					this.totalVisitsNumber = response.data.visitTargets;
				})
				// Promise.all([
				// this.$store.dispatch('statisticsHome/fetchStaff'),
				if (!this.visitsAchievements.length) {
					this.$store.dispatch('statisticsHome/fetchVisitsAchievements')
				} 

				this.$store.dispatch('statisticsHome/fetchVisitsCount', { 
					fromDate: this.moment(this.moment().startOf('month')).format('YYYY-MM-DD'), 
					toDate: this.moment().format('YYYY-MM-DD') 
				})

				// this.$store.dispatch('statisticsHome/fetchStaffRequests')

				if (
					!this.materialsStat?.materialState?.length ||
					!this.materialsStat?.topMaterial.length || 
					!this.materialsStat?.lessMaterial.length
				) {
					this.$store.dispatch('statisticsHome/fetchMaterials', { 
						fomDate: this.fromDateMaterialsChart,
						toDate: this.toDateMaterialsChart
					}).finally(() => {
						this.loadingWidelyMaterial = false
					})
				}

				// !this.teams.length ? this.$store.dispatch('statisticsHome/fetchTeams') : null,

				// not use it
				// this.$store.dispatch('statisticsHome/fetchEntities')
				
				if (!this.workloadVisits.length) {
					this.$store.dispatch('statisticsHome/fetchWorkloadVisits', { 
						fromDate: this.fromDate, 
						toDate: this.toDate,
						cityId: this.cities[0].id
					}).finally(() => {
						this.loadingClassificationStat = false
					})
				}
				if (!this.entitiesVisits.length) {
					this.$store.dispatch('statisticsHome/fetchEntitiesVisits', {
						cityId: this.cities[0].id,
						fromDate: this.fromDateVisitsRate,
						toDate: this.toDateVisitsRate,
					}).finally(() => {
						this.loadingNumberVisit = false
					})
				}
				// ])
			}).catch(() => {
				this.loading = false
			})
		},

		metaInfo: {
			title: 'Octopharma'
		},
	}
</script>
<style scoped>
.btn-num {
	font-size: 30px;
	font-weight: bolder;
	background-color: #12da1c;
}
.fs-m {
	font-size: 23px;
	font-weight: bolder;
}
</style>
